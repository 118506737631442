import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

export function useQueryParam(key) {
  const location = useLocation()
  return useMemo(() => {
    const qs = new URLSearchParams(location.search)
    const value = qs.get(key)
    return value ? value.replace(/ /g, '+') : value
  }, [location.search, key])
}
