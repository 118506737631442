import React from 'react'
import * as PropTypes from 'prop-types'
import withWidth from '@material-ui/core/es/withWidth'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import { useIntl } from 'react-intl'
import QRScanner from '../qr-scanner/qr-scanner'
import { makeStyles } from '@material-ui/styles'
import DialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Button from '@material-ui/core/Button'
import { ReactComponent as QrCode } from '../../resources/icons/QrCode.svg'
import Grid from '@material-ui/core/Grid'

const useStyles = makeStyles(theme => ({
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  contentRoot: {
    padding: `0 ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    height: 500,
    [theme.breakpoints.down('xs')]: {
      padding: 0,
      height: '100%',
    },
  },
  contentContainer: {
    height: '100%',
    width: '100%',
  },
  qrItem: {
    position: 'relative',
    width: '100%',
    flexGrow: 1,
  },
  buttonItem: {
    margin: `${theme.spacing.unit}px ${theme.spacing.unit}px ${theme.spacing
      .unit * 3}px`,
    [theme.breakpoints.down('xs')]: {
      margin: `${theme.spacing.unit}px ${theme.spacing.unit}px ${theme.spacing
        .unit * 2}px`,
    },
  },
  closeButton: {
    color: 'white',
  },
  qrIcon: {
    marginRight: 10,
  },
}))

const QRScannerDialog = ({ open, title, onClose, onSuccess, width }) => {
  const classes = useStyles()
  const scannerRef = React.useRef()
  const [loadImage, setLoadImage] = React.useState(false)
  const intl = useIntl()

  React.useEffect(() => {
    if (!loadImage) {
      return
    }
    scannerRef.current.openImageDialog()
  }, [loadImage])

  //If last scan was performed by loading a QR image, on next dialog open we
  //have to switch back to camera
  React.useEffect(() => {
    if (!open) {
      return
    }
    setLoadImage(false)
  }, [open])

  const handleLoadImageClick = () => {
    setLoadImage(true)
  }

  const handleScanSuccess = qrString => {
    onSuccess && onSuccess(qrString)
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen={width === 'xs'}
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle disableTypography className={classes.title}>
        <Typography variant="h6">{title}</Typography>
        {onClose ? (
          <IconButton className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent classes={{ root: classes.contentRoot }}>
        <Grid
          container
          alignItems="center"
          direction="column"
          className={classes.contentContainer}
        >
          <Grid item className={classes.qrItem}>
            <QRScanner
              ref={scannerRef}
              legacyMode={loadImage}
              onSuccess={handleScanSuccess}
            />
          </Grid>
          <Grid item className={classes.buttonItem}>
            <Button onClick={handleLoadImageClick}>
              <QrCode width={18} height={18} className={classes.qrIcon} />
              {intl.formatMessage({ id: 'qr.modal.loadImage' })}
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

QRScannerDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
}

export default withWidth()(QRScannerDialog)
