import { makeStyles } from '@material-ui/styles'
import React from 'react'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { useObserver } from 'mobx-react-lite'
import { useIntl } from 'react-intl'

const useStyles = makeStyles(theme => ({
  assetsContainer: {
    overflow: 'auto',
    height: 208,
    paddingRight: 12,
    [theme.breakpoints.down('xs')]: {
      height: 120,
    },
  },
  divider: {
    margin: `10px 0`,
    paddingRight: 12,
  },
}))

const AssetsBalance = ({ account }) => {
  const classes = useStyles()
  const intl = useIntl()
  return useObserver(() => (
    <Grid container direction="column">
      <Grid item container justify="space-between" style={{ paddingRight: 12 }}>
        <Typography color="textSecondary">
          {intl.formatMessage({ id: 'general.name' })}
        </Typography>
        <Typography color="textSecondary">
          {intl.formatMessage({ id: 'general.amount' })}
        </Typography>
      </Grid>
      <Divider className={classes.divider} />
      <div className={classes.assetsContainer}>
        {account &&
          account.assets.map(({ id, name, balance }) => (
            <Grid container justify="space-between" key={id}>
              <Grid item>
                <Typography>{name}</Typography>
              </Grid>
              <Grid item>
                <Typography>{balance}</Typography>
              </Grid>
            </Grid>
          ))}
      </div>
    </Grid>
  ))
}

export default AssetsBalance
