import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import * as PropTypes from 'prop-types'
import React from 'react'
import makeStyles from '@material-ui/styles/makeStyles'
import WalletButton from '../../material/button'

const useStyles = makeStyles(theme => ({
  dialog: {
    backgroundColor: theme.palette.background.contrast,
  },
  dialogFooter: {
    padding: 5,
    backgroundColor: theme.palette.background.confirmationBg,
  },
  closeBtn: {
    position: 'absolute',
    top: 10,
    right: 15,
    color: 'white',
    fontSize: '2rem',
    cursor: 'pointer',
  },
}))

const Confirmation = props => {
  const {
    handleClose,
    title,
    message,
    cancelText = 'Cancel',
    confirmText = 'Ok',
    onConfirm,
  } = props

  const classes = useStyles()

  const handleConfirm = () => {
    onConfirm()
    handleClose()
  }

  return (
    <Dialog
      open
      onClose={handleClose}
      aria-labelledby="confirmation-dialog-title"
    >
      <div className={classes.dialog}>
        <div className={classes.closeBtn} onClick={handleClose}>
          x
        </div>
        <DialogTitle id="confirmation-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
        </DialogContent>
      </div>
      <div className={classes.dialogFooter}>
        <DialogActions>
          <Button color="primary" size="large" onClick={handleClose}>
            {cancelText}
          </Button>
          <WalletButton onClick={handleConfirm}>{confirmText}</WalletButton>
        </DialogActions>
      </div>
    </Dialog>
  )
}

Confirmation.propTypes = {
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string,
  cancelText: PropTypes.string,
  confirmText: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
}

export default Confirmation
