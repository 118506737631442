import { decorate, observable } from 'mobx'

class DappAccountInfo {
  constructor(id, dapp) {
    this.id = id
    this.dapp = dapp
  }

  dapp = null
}

export default decorate(DappAccountInfo, {
  dapp: observable,
})
