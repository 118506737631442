import Hidden from '@material-ui/core/Hidden'
import React, { useState, useRef, useEffect } from 'react'
import CustodialAccountForm from '../../common/account-form/custodial-account-form'
import AccountType from '../../common/account-type/account-type'
import { useQueryParam } from '../../hooks/use-query-params'
import accountsStore from '../../stores/accounts-store'
import AccountForm from '../../common/account-form/account-form'
import ViewMnemonic from './components/view-mnemonic'
import VerifyMnemonic from './components/verify-mnemonic'
import { generateMnemonic, shuffle, split } from '../../lib/utils'
import { makeStyles } from '@material-ui/styles'
import { ReactComponent as LeftShapes } from '../../resources/icons/LeftShapes.svg'
import { ReactComponent as RightShapes } from '../../resources/icons/RightShapes.svg'
import Config from '../../config'

const Step = Object.freeze({
  ...(Config.custodial && { AccountType: 'AccountType' }),
  Form: 'Form',
  ViewMnemonic: 'ViewMnemonic',
  VerifyMnemonic: 'VerifyMnemonic',
})
const useStyles = makeStyles(theme => ({
  innerWrapper: {
    position: 'relative',
    maxWidth: 600,
    margin: 'auto',
    [theme.breakpoints.down(500)]: {
      padding: `0 ${theme.spacing.unit * 1.5}px`,
    },
  },
  leftShapes: {
    color: theme.palette.text.primary,
    position: 'absolute',
    left: -220,
    top: 10,
  },
  rightShapes: {
    color: theme.palette.text.primary,
    position: 'absolute',
    right: -220,
    top: 10,
  },
}))

const SignUp = () => {
  const email = useQueryParam('email')
  const token = useQueryParam('idpMessage')
  const classes = useStyles()
  const accounts = React.useContext(accountsStore)
  const mnemonic = useRef(generateMnemonic())
  const [step, setStep] = useState(Object.keys(Step)[token ? 1 : 0])
  const [account, setAccount] = useState(token ? { email, token } : null)
  const [isCustodial, setIsCustodial] = useState(!!token)

  useEffect(() => {
    document.getElementById('scroller').nextElementSibling.scrollTo(0, 0)
  }, [step])

  const handleTypeContinue = async isCustodialWallet => {
    setIsCustodial(isCustodialWallet)
    setStep(Step.Form)
  }

  const handleFormContinue = async account => {
    setAccount(account)
    setStep(Step.ViewMnemonic)
  }

  const handleCustodialFormContinue = async ({
    keyPair,
    authData,
    email: mail,
  }) => {
    await accounts.custodialLogin({ keyPair, authData, email: mail })
  }

  const handleViewMnemonicContinue = async () => {
    setStep(Step.VerifyMnemonic)
  }

  const handleVerifyMnemonicConfirm = async () => {
    await accounts.addAccountAndLogin(
      account.name,
      mnemonic.current,
      account.password,
    )
  }

  return (
    <div className={classes.innerWrapper}>
      <Hidden smDown>
        <LeftShapes className={classes.leftShapes} />
        <RightShapes className={classes.rightShapes} />
      </Hidden>
      {step === Step.AccountType && (
        <AccountType onContinue={handleTypeContinue} />
      )}
      {step === Step.Form && Config.custodial && isCustodial && (
        <CustodialAccountForm
          onContinue={handleCustodialFormContinue}
          initialAccount={account}
        />
      )}
      {step === Step.Form && Config.custodial && !isCustodial && (
        <AccountForm onContinue={handleFormContinue} />
      )}
      {step === Step.ViewMnemonic && (
        <ViewMnemonic
          mnemonic={mnemonic.current}
          onContinue={handleViewMnemonicContinue}
        />
      )}
      {step === Step.VerifyMnemonic && (
        <VerifyMnemonic
          mnemonic={mnemonic.current}
          mnemonicComponents={shuffle(split(mnemonic.current))}
          onConfirm={handleVerifyMnemonicConfirm}
        />
      )}
    </div>
  )
}

export default SignUp
