import { RateLimit } from 'ft3-lib'
import { action, decorate, observable } from 'mobx'
import PaymentHistory from './payment-history-store'

class DappAccount {
  constructor(dapp, ftAccount) {
    this.id = ftAccount.id_.toHex()
    this.dapp = dapp
    this.account = ftAccount
    this.loadData()
  }

  dapp = null
  points = 0
  assets = []
  paymentHistory = new PaymentHistory()

  isLoading = {
    assets: false,
    paymentHistory: false,
    transfer: false,
    transferToApplication: false,
  }

  loadData = async () => {
    await this.loadAssets()
    await this.loadPaymentHistory()
    await this.getPoints()
  }

  getPoints = async () => {
    const rateLimit = await RateLimit.getByAccountRateLimit(
      this.account.id,
      this.account.blockchain,
    )
    this.points = rateLimit.points
  }

  loadAssets = async () => {
    this.isLoading.assets = true
    try {
      await this.account.sync()
      this.assets =
        this.account.assets.map(asset => ({
          id: asset.asset.id.toHex(),
          name: asset.asset.name,
          balance: asset.amount,
        })) || []
    } finally {
      this.isLoading.assets = false
    }
  }

  loadPaymentHistory = async (pageSize = 5) => {
    this.isLoading.paymentHistory = true
    try {
      const iterator = await this.account.getPaymentHistoryIterator(pageSize)
      this.paymentHistory = new PaymentHistory(iterator)
    } finally {
      this.isLoading.paymentHistory = false
    }
  }

  set paymentHistoryPageSize(pageSize) {
    if (pageSize === this.paymentHistory.pageSize) {
      return
    }
    this.loadPaymentHistory(pageSize).then(() => {})
  }

  defaultAsset = () => {
    return this.assets.length > 0 ? this.assets[0] : null
  }

  getAssetById = assetId => {
    if (!assetId) {
      return null
    }

    return this.assets.find(asset => asset.id === assetId.toUpperCase())
  }

  sameChainTransfer = async (accountId, assetId, amount) => {
    this.isLoading.transfer = true
    try {
      await this.account.transfer(
        accountId.toBuffer(),
        assetId.toBuffer(),
        amount,
      )
    } finally {
      this.isLoading.transfer = false
    }
  }

  crossChainTransfer = async (dappId, accountId, assetId, amount) => {
    this.isLoading.transferToApplication = true
    try {
      await this.account.xcTransfer(
        dappId.toBuffer(),
        accountId.toBuffer(),
        assetId.toBuffer(),
        amount,
      )
    } finally {
      this.isLoading.transferToApplication = false
    }
  }

  transferAndLoad = async (dappId, accountId, assetId, amount) => {
    if (this.dapp.id === dappId) {
      await this.sameChainTransfer(accountId, assetId, amount)
    } else {
      await this.crossChainTransfer(dappId, accountId, assetId, amount)
    }

    await this.loadData()
  }

  deauthorize = async () => {}
}

export default decorate(DappAccount, {
  dapp: observable,
  points: observable,
  assets: observable,
  paymentHistory: observable,
  isLoading: observable,
  linkedApps: observable,
  loadAssets: action,
  loadPaymentHistory: action,
})
