import React from 'react'
import * as PropTypes from 'prop-types'
import { Redirect } from 'react-router'
import { stringify } from 'query-string'
import accountStore from '../../../stores/accounts-store'
import AccountListDialog from '../../account-list-dialog/account-list-dialog'

const QRHandlerTransfer = ({ history, data, onSuccess }) => {
  const accounts = React.useContext(accountStore)

  const accountIdsIfAccountDetailsOpened = () => {
    const match = history.location.pathname.match(
      /^\/dashboard\/([0-9a-fA-F]{64})\/([0-9a-fA-F]{64})$/,
    )

    if (!match || match.length !== 3) {
      return [null, null]
    }

    return [match[1], match[2]]
  }

  const hasMoreThanOneAccount = () => {
    return accounts.current.dappAccounts.accounts.length > 1
  }

  const getAccountId = () => {
    if (accounts.current.dappAccounts.accounts.length > 0) {
      const [account] = accounts.current.dappAccounts.accounts
      return [account.dapp.id, account.id]
    } else {
      return [null, null]
    }
  }

  const navigateToTransferDetails = (dappId, accountId) => {
    onSuccess && onSuccess()
    history.push({
      pathname: `/dashboard/${dappId}/${accountId}`,
      search: stringify(data),
    })
  }

  const redirectToTransferDetails = (dappId, accountId) => {
    onSuccess && onSuccess()
    return (
      <Redirect
        to={{
          pathname: `/dashboard/${dappId}/${accountId}`,
          search: stringify(data),
        }}
      />
    )
  }

  const [dappId, accountId] = accountIdsIfAccountDetailsOpened()

  if (dappId && accountId) {
    return redirectToTransferDetails(dappId, accountId)
  } else if (hasMoreThanOneAccount()) {
    return (
      <AccountListDialog
        open
        onClose={() => onSuccess()}
        onSelectAccount={navigateToTransferDetails}
      />
    )
  } else {
    const [dappId, accountId] = getAccountId()
    if (dappId && accountId) {
      return redirectToTransferDetails(dappId, accountId)
    }
  }
}

QRHandlerTransfer.propTypes = {
  history: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  onSuccess: PropTypes.func,
}

export default QRHandlerTransfer
