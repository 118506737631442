import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import MuiLink from '@material-ui/core/Link'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import { useIntl } from 'react-intl'
import WalletButton from '../../../material/button'
import SelectAssetField from '../../transfers/components/select-asset-field'
import Config from '../../../config'

const URL = Config.faucetBackend
const URL_INFO = {
  assets: {
    api: 'asset-requests',
    generateBodyFn: data => ({
      amount: Number(data.amount),
      assetId: data.assetId,
    }),
  },
  points: {
    api: 'point-requests',
    generateBodyFn: data => ({
      points: Number(data.amount),
    }),
  },
}

const useStyles = makeStyles(theme => ({
  infoColor: {
    color: theme.palette.info.main,
  },
  title: {
    display: 'flex',
    justifyContent: 'space=between',
    alignItems: 'center',
  },
  dialogFooter: {
    padding: 30,
    backgroundColor: theme.palette.background.confirmationBg,
  },
}))

const RequestModal = ({
  open,
  onClose,
  initialTab = 'assets',
  assets,
  accountId,
  onSuccess,
  maxPointsToRequest,
}) => {
  const classes = useStyles()
  const [tab, setTab] = React.useState(initialTab)
  const [assetId, setAssetId] = React.useState(
    !!assets.length ? assets[0].id : '',
  )
  const [amount, setAmount] = React.useState('')
  const [submitting, setSubmitting] = React.useState(false)
  const [error, setError] = React.useState('')
  const intl = useIntl()

  const handleSubmit = async () => {
    setSubmitting(true)
    const response = await fetch(`${URL}/${URL_INFO[tab].api}/`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        accountId,
        ...URL_INFO[tab].generateBodyFn({ assetId, amount }),
      }),
    })
    setSubmitting(false)
    const data = await response.json()
    if (response.ok) {
      onSuccess && onSuccess()
      onClose()
    } else {
      setError(data.message)
    }
  }

  const handleAmountChange = e => {
    const value = e.target.value
    setError('')
    setAmount(value)
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle disableTypography>
        <Grid container alignItems="center">
          <Grid item xs>
            <Typography variant="h6">
              {intl.formatMessage({ id: 'qr.modal.request.title' })}
            </Typography>
          </Grid>
          <Grid item xs="auto">
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Tabs value={tab} textColor="secondary" onChange={() => setAmount('')}>
          <Tab
            label={intl.formatMessage({ id: 'qr.modal.request.receiveAssets' })}
            value="assets"
            onClick={() => setTab('assets')}
          />
          <Tab
            label={intl.formatMessage({ id: 'qr.modal.request.receivePoints' })}
            value="points"
            onClick={() => setTab('points')}
          />
        </Tabs>
        <br />
        <br />
        <Grid container spacing={16} alignItems="center">
          <Grid item xs={6}>
            <TextField
              autoFocus
              variant="outlined"
              label={intl.formatMessage({ id: 'general.amount' })}
              name="amount"
              fullWidth
              value={amount}
              onChange={handleAmountChange}
            />
          </Grid>
          {tab === 'assets' ? (
            <Grid item style={{ minWidth: 150 }}>
              <SelectAssetField
                options={assets}
                value={assetId}
                onChange={({ value }) => setAssetId(value)}
              />
            </Grid>
          ) : (
            <MuiLink
              underline="none"
              variant="body2"
              className={classes.infoColor}
              onClick={() => setAmount(maxPointsToRequest)}
              style={{ marginLeft: 10 }}
            >
              {intl.formatMessage({ id: 'qr.modal.request.max' })}
            </MuiLink>
          )}
        </Grid>
        <br />
        <Typography color="error" align="center" gutterBottom={false}>
          {error}
        </Typography>
      </DialogContent>
      <Grid className={classes.dialogFooter} container justify="center">
        <WalletButton
          disabled={!amount || submitting || !!error}
          onClick={handleSubmit}
          busy={submitting}
        >
          {intl.formatMessage({ id: 'qr.modal.request.submit' })}
        </WalletButton>
      </Grid>
    </Dialog>
  )
}

export default RequestModal
