import { decorate, observable } from 'mobx'

class NullIterator {
  rewind() {
    return []
  }

  next() {
    return []
  }

  prev() {
    return []
  }

  fastForward() {
    return []
  }

  hasMore() {
    return false
  }

  get totalCount() {
    return 0
  }

  get pageCount() {
    return 0
  }

  get current() {
    return 0
  }

  get pageSize() {
    return 0
  }
}

class PaymentHistory {
  constructor(iterator = new NullIterator()) {
    this.iterator = iterator
    this.entries = iterator.next()
    this.pageCount = iterator.pageCount
    this.totalCount = iterator.totalCount
    this.current = iterator.current
    this.pageSize = iterator.pageSize
  }

  loadFirstPage() {
    this.entries = this.iterator.rewind()
    this.current = this.iterator.current
  }

  loadNextPage() {
    this.entries = this.iterator.next()
    this.current = this.iterator.current
  }

  loadPrevPage() {
    this.entries = this.iterator.prev()
    this.current = this.iterator.current
  }

  loadLastPage() {
    this.entries = this.iterator.fastForward()
    this.current = this.iterator.current
  }

  hasMore() {
    return this.iterator.hasMore()
  }
}

export default decorate(PaymentHistory, {
  entries: observable,
  pageCount: observable,
  current: observable,
})
