import React, { useContext, useState } from 'react'
import { parse } from 'query-string'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useIntl } from 'react-intl'
import CenterComponent from '../../common/center-component/center-component'
import AuthorizeForm from './components/authorize-form'
import ErrorLabel from '../../common/error-label/error-label'
import accountsStore from '../../stores/accounts-store'
import DirectoryService from '../../lib/directory-service'
import {
  Blockchain,
  SingleSignatureAuthDescriptor,
  FlagsType,
  User,
} from 'ft3-lib'

const RequestState = {
  NotInitiated: 'NotInitiated',
  Loading: 'Loading',
  Failed: 'Failed',
  Completed: 'Completed',
}

const Authorize = ({ location: { search } }) => {
  const accounts = useContext(accountsStore)
  const [dappAccountDownloadState, setDappAccountDownloadState] = useState({
    account: null,
    state: RequestState.NotInitiated,
  })
  const intl = useIntl()

  const query = parse(search)

  const { dappId, pubkey, successAction, cancelAction } = query

  const [error, setError] = useState()
  const [dappSession, setDappSession] = useState()

  if (!dappId) {
    return (
      <CenterComponent>
        <ErrorLabel
          text={intl.formatMessage({ id: 'authorize.error.missingId' })}
        />
      </CenterComponent>
    )
  }

  if (!pubkey) {
    return (
      <CenterComponent>
        <ErrorLabel
          text={intl.formatMessage({ id: 'authorize.error.missingKey' })}
        />
      </CenterComponent>
    )
  }

  if (dappAccountDownloadState.state === RequestState.NotInitiated) {
    const authDescriptor = new SingleSignatureAuthDescriptor(
      accounts.current.keyPair.pubKey,
      [FlagsType.Account, FlagsType.Transfer],
    )

    const user = new User(accounts.current.keyPair, authDescriptor)

    Blockchain.initialize(dappId.toBuffer(), new DirectoryService())
      .then(blockchain => {
        const session = blockchain.newSession(user)
        setDappSession(session)
        return session.getAccountById(accounts.current.id)
      })
      .then(account => {
        setDappAccountDownloadState({
          account,
          state: RequestState.Completed,
        })
      })
      .catch(error => {
        setError(error.message)
        setDappAccountDownloadState(data => ({
          ...data,
          state: RequestState.Failed,
        }))
      })

    setDappAccountDownloadState(data => ({
      ...data,
      state: RequestState.Loading,
    }))
  }

  if (error) {
    return (
      <CenterComponent>
        <ErrorLabel text={error} />
      </CenterComponent>
    )
  } else if (dappAccountDownloadState.state === RequestState.Loading) {
    return (
      <CenterComponent>
        <CircularProgress />
      </CenterComponent>
    )
  } else {
    return (
      <AuthorizeForm
        dappSession={dappSession}
        dappAccount={dappAccountDownloadState.account}
        dappId={dappId}
        vaultAccount={accounts.current}
        pubkey={pubkey}
        successAction={successAction}
        cancelAction={cancelAction}
      />
    )
  }
}

export default Authorize
