const { ChainConnectionInfo } = require('ft3-lib')
const Config = require('../config')

function DirectoryService() {
  let customDapps = []

  if (typeof window !== 'undefined') {
    const customDappsString = window.localStorage.getItem('custom-dapps')
    customDapps = JSON.parse(customDappsString) || []
  }

  const chains = Config.chains.concat(
    customDapps.map(dapp => ({
      rid: dapp.rid,
      url: dapp.port ? `${dapp.host}:${dapp.port}/` : `${dapp.host}/`,
    })),
  )
  this.chainInfos = chains.map(
    ({ rid, url }) => new ChainConnectionInfo(Buffer.from(rid, 'hex'), url),
  )
}

DirectoryService.prototype.getChainConnectionInfo = function(id) {
  return this.chainInfos.find(
    info =>
      info.chainId.toString('hex').toUpperCase() ===
      (typeof id === 'string' || id instanceof String
        ? id.toUpperCase()
        : id.toString('hex').toUpperCase()),
  )
}

DirectoryService.prototype.getDapps = function() {
  return Config.dapps
}

module.exports = DirectoryService
