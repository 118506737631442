import { createMuiTheme } from '@material-ui/core'
import getPalette from './palette'

const getTheme = (type = 'dark', { primary, secondary, error, info }) =>
  createMuiTheme({
    typography: {
      useNextVariants: true,
      titleFont: 'Battlefin-Black',
      fontFamily: '"NB-International", "Roboto", "Helvetica", sans-serif',
      button: {
        fontSize: 16,
        fontWeight: 600,
        letterSpacing: '1px',
      },
      subtitle1: {
        fontSize: 18,
      },
      h6: {
        fontWeight: 'bold',
      },
    },
    props: {
      MuiButtonBase: {
        disableRipple: true,
      },
    },
    palette: {
      primary,
      secondary,
      error,
      info,
      type,
      borders: type === 'dark' ? '#eeeeee' : 'rgba(0, 0, 0, 0.12)',
      background: {
        paper: type === 'dark' ? '#1E191E' : '#F8F8F8',
        default: type === 'dark' ? '#1E191E' : '#F8F8F8',
        contrast: type === 'dark' ? '#2E2E2E' : 'white',
        secondary: type === 'dark' ? '#2F4968' : '#F8F8F8',
        confirmationBg: type === 'dark' ? '#3d3d3d' : '#F2F2F2',
      },
    },
    overrides: {
      MuiChip: {
        colorSecondary: {
          height: 22,
        },
        iconColorSecondary: {
          height: 20,
          width: 20,
          marginLeft: 0,
          fontSize: '0.8rem',
          color: secondary.main,
          backgroundColor: 'rgba(255, 255, 255, 0.7)',
        },
        deleteIconColorSecondary: {
          width: 17,
        },
      },
      MuiTableCell: {
        root: {
          borderBottom: 'none',
          '@media (max-width: 600px)': {
            padding: '4px 8px 4px 8px',
          },
        },
      },
      MuiLinearProgress: {
        root: {
          height: 6,
          borderRadius: 6,
        },
        barColorPrimary: {
          backgroundColor: info.main,
        },
        determinate: {
          backgroundColor: '#2d2d2d',
        },
      },
      MuiTableHead: {
        root: {
          borderBottom: '1px solid rgba(81, 81, 81, 1)',
        },
      },
      MuiTablePagination: {
        root: {
          borderTop: '1px solid rgba(81, 81, 81, 1)',
        },
        caption: {
          '@media (max-width: 600px)': {
            display: 'none',
          },
        },
        selectRoot: {
          '@media (max-width: 600px)': {
            marginRight: 8,
          },
        },
      },
      MuiLink: {
        root: {
          '&:hover': {
            cursor: 'pointer',
          },
          '&.active': {
            color: primary.main,
          },
        },
      },
      MuiTabs: {
        root: {
          minHeight: 36,
          borderBottom: '1px solid rgba(81, 81, 81, 1)',
          '& button': {
            minHeight: 36,
          },
        },
      },
      MuiTab: {
        root: {
          textTransform: 'capitalize',
        },
        label: {
          fontSize: '0.9rem',
        },
      },
      MuiButton: {
        raised: {
          borderRadius: 100,
        },
      },
      MuiAppBar: {
        root: {
          boxShadow: 'none',
        },
      },
      MuiListItemText: {
        primary: {
          fontSize: '1.1rem',
        },
      },
      MuiTypography: {
        root: {
          '&.opacity': {
            opacity: 0.5,
          },
          '&.opacity7': {
            opacity: 0.7,
          },
        },
      },
      MuiInputBase: {
        root: {
          backgroundColor: 'transparent',
        },
      },
      MuiDialog: {
        root: {
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
        },
      },
    },
  })

export default function(type) {
  const palette = getPalette(type)
  const theme = getTheme(type, palette)

  return { ...theme }
}
