import React from 'react'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import Hidden from '@material-ui/core/Hidden'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/styles'
import { FormattedMessage, useIntl } from 'react-intl'
import useLocalStorage from '../../hooks/local-storage'
import Custodial from './components/custodial'
import NonCustodial from './components/non-custodial'
import { ReactComponent as LeftShapes } from '../../resources/icons/LeftShapes.svg'
import { ReactComponent as RightShapes } from '../../resources/icons/RightShapes.svg'
import { useObserver } from 'mobx-react-lite'

const useStyles = makeStyles(theme => ({
  wrapper: {
    maxWidth: 600,
    margin: 'auto',
    marginTop: theme.spacing.unit * 10,
    [theme.breakpoints.down(500)]: {
      padding: `0 ${theme.spacing.unit * 1.5}px`,
    },
    [`${theme.breakpoints.down(700)} and (orientation: landscape)`]: {
      marginTop: 0,
    },
  },
  title: {
    [theme.breakpoints.down(600)]: {
      fontSize: 30,
    },
    fontSize: 50,
    fontFamily: theme.typography.titleFont,
  },
  list: {
    marginBottom: `${theme.spacing.unit * 3}px`,
    marginTop: `${theme.spacing.unit * 6}px`,
    borderRadius: 4,
    border: `1px solid ${theme.palette.borders}`,
  },
  actions: {
    textAlign: 'center',
    margin: `${theme.spacing.unit * 4}px auto 0`,
  },
  button: {
    maxWidth: 280,
    width: '100%',
  },
  or: {
    margin: `${theme.spacing.unit}px 0`,
  },
  innerWrapper: {
    marginTop: theme.spacing.unit * 4,
    position: 'relative',
    maxWidth: 600,
    margin: 'auto',
    [theme.breakpoints.down(500)]: {
      padding: `0 ${theme.spacing.unit * 1.5}px`,
    },
  },
  leftShapes: {
    color: theme.palette.text.primary,
    position: 'absolute',
    left: -220,
    top: -128,
  },
  rightShapes: {
    color: theme.palette.text.primary,
    position: 'absolute',
    right: -220,
    top: -128,
  },
}))

const SignIn = () => {
  const classes = useStyles()
  const intl = useIntl()
  const [tab, setTab] = useLocalStorage('wallet-sign-in-tab', 'non-custodial')

  return useObserver(() => (
    <div className={classes.wrapper}>
      <FormattedMessage id="sign.in.access.account">
        {text => (
          <Typography
            variant="h3"
            paragraph
            align="center"
            className={classes.title}
          >
            {text}
          </Typography>
        )}
      </FormattedMessage>
      <Tabs value={tab} textColor="secondary" centered variant="fullWidth">
        <Tab
          label={intl.formatMessage({ id: 'sign.in.tabs.non-custodial' })}
          value="non-custodial"
          onClick={() => setTab('non-custodial')}
        />
        <Tab
          label={intl.formatMessage({ id: 'sign.in.tabs.custodial' })}
          value="custodial"
          onClick={() => setTab('custodial')}
        />
      </Tabs>
      <div className={classes.innerWrapper}>
        <Hidden smDown>
          <LeftShapes className={classes.leftShapes} />
          <RightShapes className={classes.rightShapes} />
        </Hidden>
        {tab === 'non-custodial' && <NonCustodial />}
        {tab === 'custodial' && <Custodial />}
      </div>
    </div>
  ))
}

export default SignIn
