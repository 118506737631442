import { action, decorate, observable, computed } from 'mobx'
import Dapp from './dapp-store'
import DappAccount from './dapp-account-store'
import DappAccountInfo from './dapp-account-info-store'
import { getBlockchains } from '../lib/blockchain'

class DappAccounts {
  constructor(masterAccount, session) {
    this.masterAccount = masterAccount
    this.session = session
    this.accountCache = [masterAccount] //TODO: Replace with dictionary
    this.masterAccountInfo = new DappAccountInfo(
      masterAccount.id,
      masterAccount.dapp.info,
    )
    this.loadData()
  }

  isLoading = false
  apps = []

  get accounts() {
    return [this.masterAccountInfo].concat(
      this.apps.flatMap(({ accounts }) => accounts),
    )
  }

  get count() {
    return this.accounts.length
  }

  loadData = async () => {
    this.isLoading = true
    try {
      ;(await getBlockchains()).forEach(chain => {
        const app = this.apps.find(({ id }) => chain.id.toHex() === id)
        if (app) {
          app.loadData()
        } else {
          this.apps.push(new Dapp(chain.newSession(this.session.user), false))
        }
      })
    } finally {
      this.isLoading = false
    }
  }

  getById = async (dappId, accountId) => {
    let cachedAccount = this.accountCache.find(
      ({ id, dapp }) => accountId === id && dapp.id === dappId,
    )

    if (cachedAccount) {
      cachedAccount.loadData()
      return cachedAccount
    }

    const accountInfo = this.accounts.find(
      ({ id, dapp }) => id === accountId && dapp.id === dappId,
    )
    if (!accountInfo) {
      throw new Error('Unknown dapp account')
    }

    const session = accountInfo.dapp.chain.newSession(this.session.user)
    const acc = await session.getAccountById(accountInfo.id.toBuffer())

    const dapp = new Dapp(session, false)
    const account = new DappAccount(dapp, acc)
    this.accountCache.push(account)
    return account
  }
}

export default decorate(DappAccounts, {
  name: observable,
  apps: observable,
  accounts: computed,
  masterAccountInfo: observable,
  isLoading: observable,
  loadData: action,
})
