import React from 'react'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import Print from '@material-ui/icons/Print'
import makeStyles from '@material-ui/styles/makeStyles'
import { useIntl } from 'react-intl'
import WalletButton from '../../../material/button'

const useStyles = makeStyles(theme => ({
  wrapper: {
    maxWidth: 600,
    margin: 'auto',
    marginTop: theme.spacing.unit * 10,
    [theme.breakpoints.down(500)]: {
      padding: `0 ${theme.spacing.unit * 1.5}px`,
    },
  },
  title: {
    [theme.breakpoints.down(600)]: {
      fontSize: 30,
    },
    fontSize: 50,
    fontFamily: theme.typography.titleFont,
  },
  mnemonicContainer: {
    maxWidth: 420,
    minHeight: 100,
    margin: `${theme.spacing.unit * 6}px auto 0`,
    padding: theme.spacing.unit * 2,
    borderRadius: 5,
    backgroundColor: theme.palette.background.contrast,
    '& > div': {
      paddingTop: theme.spacing.unit * 2,
    },
  },
  actions: {
    margin: `${theme.spacing.unit * 4}px 0`,
    textAlign: 'center',
  },
}))

const ViewMnemonic = ({ mnemonic, onContinue }) => {
  const classes = useStyles()
  const intl = useIntl()

  const handleClick = () => {
    onContinue && onContinue()
  }

  return (
    <div className={classes.wrapper}>
      <Typography
        variant="h3"
        paragraph
        align="center"
        className={classes.title}
      >
        {intl.formatMessage({ id: 'sign.up.mnemonic.title' })}
      </Typography>
      <Typography variant="subtitle1" align="center">
        {intl.formatMessage({ id: 'sign.up.mnemonic.subtitle' })}
      </Typography>
      <div className={classes.mnemonicContainer}>
        <Typography align="center" color="textSecondary">
          {mnemonic}
        </Typography>
        <Grid container justify="flex-end">
          <Grid item>
            <IconButton
              onClick={() => {
                const pri = document.getElementById('print').contentWindow
                pri.document.open()
                pri.document.write(mnemonic)
                pri.document.close()
                pri.focus()
                pri.print()
              }}
            >
              <Print />
            </IconButton>
          </Grid>
        </Grid>
      </div>
      <div className={classes.actions}>
        <WalletButton onClick={handleClick}>
          {intl.formatMessage({ id: 'sign.up.mnemonic.submit' })}
        </WalletButton>
      </div>
      <iframe
        id="print"
        title="mnemonic"
        style={{ height: 0, width: 0, position: 'absolute', border: 'none' }}
      />
    </div>
  )
}

export default ViewMnemonic
