import React from 'react'
import * as PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import Grid from '@material-ui/core/Grid'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import CloseIcon from '@material-ui/icons/Close'
import { makeStyles } from '@material-ui/styles'
import TextField from '@material-ui/core/TextField'
import { useIntl } from 'react-intl'
import WalletButton from '../../../material/button'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'

const inputProps = {
  color: 'secondary',
  variant: 'outlined',
  fullWidth: true,
}

const useStyles = makeStyles(theme => ({
  dialogFooter: {
    padding: 30,
    backgroundColor: theme.palette.background.confirmationBg,
  },
}))

const ManageCustomDapp = props => {
  const { handleClose, onAdd, dapp, onRemove, onEdit } = props

  const classes = useStyles()
  const [name, setName] = React.useState(!dapp ? '' : dapp.name)
  const [host, setHost] = React.useState(!dapp ? '' : dapp.host)
  const [port, setPort] = React.useState(!dapp ? '' : dapp.port)
  const [web, setWeb] = React.useState(!dapp ? '' : dapp.web)
  const [rid, setRid] = React.useState(!dapp ? '' : dapp.rid)
  const intl = useIntl()
  const handleConfirm = e => {
    e.preventDefault()
    const handler = !!dapp ? onEdit : onAdd
    handler(
      {
        name: name.trim(),
        rid: rid.trim(),
        web: web.indexOf('//') === -1 ? `//${web.trim()}` : web.trim(),
        host: host.trim(),
        port: port.trim(),
      },
      dapp,
    )
    handleClose()
  }

  const handleRemove = () => {
    handleClose()
    onRemove(dapp)
  }

  return (
    <Dialog open onClose={handleClose}>
      <form onSubmit={handleConfirm}>
        <DialogTitle disableTypography>
          <Grid container alignItems="center">
            <Grid item xs>
              <Typography variant="h6">
                {intl.formatMessage({
                  id: `addDapp.${dapp ? 'editTitle' : 'addTitle'}`,
                })}
              </Typography>
            </Grid>
            <Grid item xs="auto">
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent style={{ paddingTop: 20 }}>
          <Grid container spacing={16}>
            <Grid item sm={6} xs>
              <TextField
                {...inputProps}
                label={intl.formatMessage({ id: 'addDapp.name' })}
                value={name}
                autoFocus
                onChange={({ target: { value } }) => {
                  setName(value)
                }}
              />
            </Grid>
            <Grid item container spacing={16}>
              <Grid item sm={6} xs>
                <TextField
                  {...inputProps}
                  label={intl.formatMessage({ id: 'addDapp.host' })}
                  value={host}
                  onChange={({ target: { value } }) => {
                    setHost(value)
                  }}
                />
              </Grid>
              <Grid item sm={6} xs>
                <TextField
                  {...inputProps}
                  label={intl.formatMessage({ id: 'addDapp.port' })}
                  value={port}
                  onChange={({ target: { value } }) => {
                    setPort(value)
                  }}
                />
              </Grid>
            </Grid>
            <Grid item sm={12} xs>
              <TextField
                color="secondary"
                variant="outlined"
                label={intl.formatMessage({ id: 'addDapp.web' })}
                fullWidth
                value={web}
                onChange={({ target: { value } }) => {
                  setWeb(value)
                }}
              />
            </Grid>
            <Grid item sm={12} xs>
              <TextField
                color="secondary"
                variant="outlined"
                label={intl.formatMessage({ id: 'addDapp.chainId' })}
                fullWidth
                value={rid}
                onChange={({ target: { value } }) => {
                  setRid(value)
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <Grid
          className={classes.dialogFooter}
          container
          justify="center"
          alignItems="center"
        >
          {!!dapp && (
            <div>
              <WalletButton variant="text" onClick={handleRemove}>
                {intl.formatMessage({ id: 'addDapp.remove' })}
              </WalletButton>
            </div>
          )}
          <div style={{ padding: 16 }}>
            <WalletButton
              type="submit"
              disabled={
                !name || !host || !rid || !/^[a-zA-Z0-9]{64}$/g.test(rid)
              }
            >
              {intl.formatMessage({
                id: `addDapp.${!!dapp ? 'submitEdit' : 'submitAdd'}`,
              })}
            </WalletButton>
          </div>
        </Grid>
      </form>
    </Dialog>
  )
}

ManageCustomDapp.propTypes = {
  onAdd: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
}

export default ManageCustomDapp
