import Config from '../config'
import { AssetBalance } from 'ft3-lib'

export async function giveBalanceIfNeeded(account, blockchain) {
  if (
    Config.giveBalance &&
    Config.giveBalance.asset &&
    Config.giveBalance.amount
  ) {
    const assets = await blockchain.getAssetsByName(Config.giveBalance.asset)

    assets &&
      assets.length > 0 &&
      (await AssetBalance.giveBalance(
        account.id,
        assets[0].id,
        Config.giveBalance.amount,
        blockchain,
      ))
  }
}
