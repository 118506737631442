import Grid from '@material-ui/core/Grid'
import Tooltip from '@material-ui/core/Tooltip'
import { makeStyles } from '@material-ui/styles'
import React, { Fragment } from 'react'
import { useIntl } from 'react-intl'
import RoboHash from '../../../../common/robo-hash/robo-hash'
import Toast from '../../../../common/toast/toast'
import { copyToClipboard } from '../../../../common/clipboard/clipboard'

const useStyles = makeStyles(theme => ({
  roboHash: {
    position: 'absolute',
    top: -13,
    right: -13,
  },
  roboHashInline: {
    [theme.breakpoints.down('xs')]: {
      position: 'absolute',
      right: -13,
    },
    [theme.breakpoints.up('sm')]: {
      marginLeft: 10,
    },
  },
  imageContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  appImageWrapper: {
    position: 'relative',
    display: 'flex',
    cursor: 'pointer',
    '& svg': {
      borderRadius: 8,
    },
  },
  popper: {
    whiteSpace: 'pre-line',
    wordBreak: 'break-all',
    color: 'white',
  },
}))

const AppImage = ({
  appId,
  userId,
  appSize,
  userSize,
  inline,
  withTooltip,
  className,
}) => {
  const classes = useStyles()
  const Wrapper = withTooltip ? Tooltip : Fragment
  const [snackbar, setSnackbar] = React.useState(false)
  const intl = useIntl()

  const copy = e => {
    if (!withTooltip) return
    e.preventDefault()
    copyToClipboard(`${appId};${userId}`)
    setSnackbar(true)
  }

  const title = intl.formatMessage(
    {
      id: 'general.addressTooltip',
    },
    { appId, userId },
  )

  return (
    <Grid container justify="center" className={className}>
      <Grid item className={classes.appImageWrapper} onClick={copy}>
        <Wrapper
          {...(withTooltip && {
            title: title,
            placement: 'top',
            classes: { popper: classes.popper },
          })}
        >
          <div className={classes.imageContainer}>
            <svg
              width={appSize}
              height={appSize}
              data-jdenticon-value={appId}
            />
            {userId && (
              <div className={classes[!inline ? 'roboHash' : 'roboHashInline']}>
                <RoboHash size={userSize} id={userId} />
              </div>
            )}
          </div>
        </Wrapper>
      </Grid>
      {snackbar && (
        <Toast
          message={intl.formatMessage(
            { id: 'general.clipboard' },
            {
              field: intl.formatMessage({
                id: 'general.address',
              }),
            },
          )}
          close={() => setSnackbar()}
        />
      )}
    </Grid>
  )
}

export default AppImage
