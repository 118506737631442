import React, { useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { useIntl } from 'react-intl'
import { resetPassword, verifyEmail } from '../../api/api'
import { useQueryParam } from '../../hooks/use-query-params'
import { generateUserData } from '../../lib/utils'
import {
  equalValues,
  minLength,
  required,
  validate,
} from '../../lib/validations'
import WalletButton from '../../material/button'

import { makeStyles } from '@material-ui/styles'
import { ReactComponent as LeftShapes } from '../../resources/icons/LeftShapes.svg'
import { ReactComponent as RightShapes } from '../../resources/icons/RightShapes.svg'

const useStyles = makeStyles(theme => ({
  innerWrapper: {
    marginTop: theme.spacing.unit * 10,
    position: 'relative',
    maxWidth: 600,
    margin: 'auto',
    [theme.breakpoints.down(500)]: {
      padding: `0 ${theme.spacing.unit * 1.5}px`,
    },
    [`${theme.breakpoints.down(700)} and (orientation: landscape)`]: {
      marginTop: 0,
    },
  },
  title: {
    [theme.breakpoints.down(600)]: {
      fontSize: 30,
    },
    fontSize: 50,
    fontFamily: theme.typography.titleFont,
  },
  subtitle: {
    marginBottom: theme.spacing.unit * 1,
  },
  leftShapes: {
    color: theme.palette.text.primary,
    position: 'absolute',
    left: -220,
    top: 10,
  },
  rightShapes: {
    color: theme.palette.text.primary,
    position: 'absolute',
    right: -220,
    top: 10,
  },
  actions: {
    marginTop: 20,
    display: 'flex',
    justifyContent: 'center',
  },
}))

const ResetPassword = () => {
  const email = useQueryParam('email')
  const token = useQueryParam('idpMessage')
  const classes = useStyles()
  const [busy, setBusy] = React.useState(false)
  const [error, setError] = React.useState('')
  const [mailSent, setMailSent] = React.useState(false)
  const intl = useIntl()
  const [account, setAccount] = useState({
    email,
    token,
    password: '',
    confirmPassword: '',
  })
  const handleTextFieldChange = ({ target: { name, value } }) => {
    setAccount(account => ({ ...account, [name]: value }))
    setError('')
  }

  const scrollHookRef = React.useRef(null)

  useEffect(() => {
    if (!error && !mailSent) {
      return
    }
    scrollHookRef.current.scrollIntoView()
  }, [error, mailSent])

  const handleContinueClick = async e => {
    e.preventDefault()

    const { email, password, confirmPassword, token } = account

    if (!token) {
      sendVerification()
      return
    }

    if (
      !validate(
        [
          [token, [required('sign.up.error.token')]],
          [
            password,
            [
              required('sign.up.error.password'),
              minLength(6, 'sign.up.error.minPassword'),
            ],
          ],
          [confirmPassword, [equalValues(password, 'sign.up.error.verify')]],
        ],
        error => {
          setError(intl.formatMessage({ id: error }))
        },
      )
    ) {
      return
    }
    setBusy(true)
    const { encMsg } = generateUserData(email, password)
    const response = await resetPassword({
      name: email,
      encMsg,
      identityProviderMsg: token,
    })
    setBusy(false)
    if (!response.ok) {
      try {
        const data = await response.json()
        if (data.message === 'No registration for that username found') {
          return setError(data.message)
        }
      } catch (e) {}
      return setError(intl.formatMessage({ id: 'reset.unknownError' }))
    }
    const data = await response.json()
    console.log('data = ', data)
  }

  const sendVerification = async () => {
    try {
      setBusy(true)
      const response = await verifyEmail(account.email, 'reset-password')
      if (!response.ok) {
        return setError(intl.formatMessage({ id: 'reset.unknownError' }))
      } else {
        setMailSent(true)
        setError('')
      }
    } catch (err) {
      console.log('err = ', err)
    } finally {
      setBusy(false)
    }
  }

  return (
    <div className={classes.innerWrapper}>
      <Hidden smDown>
        <LeftShapes className={classes.leftShapes} />
        <RightShapes className={classes.rightShapes} />
      </Hidden>
      <form onSubmit={handleContinueClick}>
        <Typography
          variant="h3"
          paragraph
          align="center"
          className={classes.title}
        >
          {intl.formatMessage({ id: 'reset.title' })}
        </Typography>
        <span ref={scrollHookRef} />
        {error && (
          <Typography color="error" align="center">
            {error}
          </Typography>
        )}

        <Typography
          variant="subtitle1"
          align="center"
          className={classes.subtitle}
        >
          {intl.formatMessage({ id: 'reset.subtitle' }, { email })}
        </Typography>
        <Grid
          item
          container
          direction="column"
          spacing={24}
          className={classes.fields}
        >
          <Grid item xs>
            <TextField
              variant="outlined"
              label={intl.formatMessage({ id: 'reset.emailLabel' })}
              name="email"
              fullWidth
              value={account.email || ''}
              onChange={handleTextFieldChange}
              autoFocus
            />
          </Grid>
          {mailSent && (
            <Grid item xs>
              <TextField
                variant="outlined"
                label={intl.formatMessage({ id: 'reset.codeLabel' })}
                name="token"
                fullWidth
                value={account.token || ''}
                onChange={handleTextFieldChange}
              />
            </Grid>
          )}
          {account.token && (
            <>
              <Grid item xs>
                <TextField
                  variant="outlined"
                  label={intl.formatMessage({ id: 'reset.password' })}
                  type="password"
                  name="password"
                  fullWidth
                  value={account.password}
                  onChange={handleTextFieldChange}
                />
              </Grid>
              <Grid item xs>
                <TextField
                  variant="outlined"
                  label={intl.formatMessage({ id: 'reset.confirmPassword' })}
                  type="password"
                  fullWidth
                  name="confirmPassword"
                  value={account.confirmPassword}
                  onChange={handleTextFieldChange}
                />
              </Grid>
            </>
          )}
        </Grid>
        <div className={classes.actions}>
          {account.token ? (
            <WalletButton
              type="submit"
              busy={busy}
              disabled={
                Boolean(error) ||
                busy ||
                !account.password ||
                !account.confirmPassword ||
                account.password !== account.confirmPassword
              }
            >
              {intl.formatMessage({ id: 'reset.button' })}
            </WalletButton>
          ) : (
            <WalletButton
              busy={busy}
              disabled={Boolean(error) || busy || !account.email}
            >
              {intl.formatMessage({ id: 'sign.up.sendConfirmationCode' })}
            </WalletButton>
          )}
        </div>
      </form>
    </div>
  )
}

export default ResetPassword
