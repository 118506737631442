import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import * as PropTypes from 'prop-types'
import { observer } from 'mobx-react-lite'
import { useIntl } from 'react-intl'
import TransferConfirmation from './transfer-confirmation'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import RoboHash from '../../../common/robo-hash/robo-hash'
import Toast from '../../../common/toast/toast'
import Config from '../../../config'
import {
  validate,
  required,
  isInteger,
  greaterThan,
  lessOrEqual,
  accountId as accountIdValidator,
} from '../../../lib/validations'
import WalletButton from '../../../material/button'
import AppImage from '../../linked-apps/components/app-card/app-image'
import { ReactComponent as SendIcon } from '../../../resources/icons/send.svg'
import SelectAssetField from './select-asset-field'
import { getFirstNLastN } from '../../../lib/utils'

const useStyles = makeStyles(theme => ({
  formGrid: {
    marginTop: 10,
    backgroundColor: theme.palette.background.confirmationBg,
    '& button': {
      width: 160,
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
  },
  inputsWrapper: {
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'stretch',
    },
  },
  placeholders: {
    width: 200,
  },
}))

const Transfer = observer(({ account, onSuccess, onChange, transferData }) => {
  const [snackbar, setSnackbar] = useState(false)
  const [confirm, setConfirm] = useState(false)
  const [error, setError] = useState('')
  const [busy, setBusy] = React.useState(false)
  const intl = useIntl()

  const classes = useStyles()
  const asset = account.getAssetById(transferData.assetId)

  useEffect(() => {
    const { applicationId } = transferData
    if (
      applicationId &&
      !Config.crossChainTransfersEnabled &&
      account.dapp.id.toUpperCase() !== applicationId.toUpperCase()
    ) {
      setError(intl.formatMessage({ id: 'dapp.error.crossChain' }))
    } else {
      setError('')
    }
  }, [transferData]) // eslint-disable-line

  const handleAmountChange = ({ target: { value } }) => {
    onChange({
      ...transferData,
      amount:
        value && value.length > 0
          ? /^[-+]?([0-9]+(e([0-9]+)?)?)?$/.test(value)
            ? value
            : transferData.amount
          : null,
    })
  }

  const address = () => {
    const { applicationId, accountId } = transferData
    if (!applicationId && !accountId) return ''
    if (!applicationId && accountId) return accountId
    if (!accountId && applicationId) return applicationId

    return `${applicationId};${accountId}`
  }

  const handleAddressChange = ({ target: { value = '' } }) => {
    const [applicationId, accountId] = value.toUpperCase().split(';')
    onChange({
      ...transferData,
      accountId,
      applicationId,
    })
  }

  const getAppDisplayName = applicationId => {
    const matchedApps = account.dapp.getByIdOrName(applicationId)
    if (!matchedApps || matchedApps.length !== 1) {
      return null
    }
    return matchedApps[0].name || getFirstNLastN(matchedApps[0].id)
  }

  const handleTransferClick = e => {
    e.preventDefault()
    const { applicationId, accountId, amount, assetId } = transferData

    if (
      !validate(
        [
          [
            applicationId,
            [required(intl.formatMessage({ id: 'dapp.error.application' }))],
          ],
          [
            accountId,
            [required(intl.formatMessage({ id: 'dapp.error.recipient' }))],
          ],
          [
            Number(amount),
            [
              isInteger(intl.formatMessage({ id: 'dapp.error.amount' })),
              greaterThan(
                0,
                intl.formatMessage({ id: 'dapp.error.positiveAmount' }),
              ),
              lessOrEqual(
                asset.balance,
                intl.formatMessage({ id: 'dapp.error.inRange' }),
              ),
            ],
          ],
          [assetId, [required(intl.formatMessage({ id: 'dapp.error.asset' }))]],
        ],
        setError,
      )
    ) {
      return
    }

    setConfirm(true)
  }
  const addressError =
    (transferData.applicationId &&
      accountIdValidator(intl.formatMessage({ id: 'dapp.error.addresses' }))(
        transferData.applicationId,
      )) ||
    (transferData.accountId &&
      accountIdValidator(intl.formatMessage({ id: 'dapp.error.addresses' }))(
        transferData.accountId,
      ))

  const onConfirm = async () => {
    setConfirm(false)
    const { applicationId, accountId, amount, assetId } = transferData
    try {
      setBusy(true)
      await account.transferAndLoad(
        applicationId,
        accountId,
        assetId,
        Number(amount),
      )
      setSnackbar(true)
      onChange({ assetId: '', applicationId: '' })
      onSuccess && onSuccess()
    } catch {
      setError(intl.formatMessage({ id: 'dapp.error.transferFailed' }))
    } finally {
      setConfirm(false)
      setBusy(false)
    }
  }

  if (!transferData.assetId && account.defaultAsset()) {
    onChange({
      ...transferData,
      assetId: account.defaultAsset().id,
    })
  }

  return (
    <form onSubmit={handleTransferClick}>
      <Grid container direction="column" spacing={24}>
        {error && (
          <Grid item>
            <Typography color="error">{error}</Typography>
          </Grid>
        )}
        <Grid
          item
          container
          spacing={16}
          alignItems="center"
          className={classes.inputsWrapper}
        >
          <Grid item xs>
            <TextField
              variant="outlined"
              label={intl.formatMessage({ id: 'general.address' })}
              name="address"
              onChange={handleAddressChange}
              value={address()}
              rows={5}
              multiline
              fullWidth
              error={!!addressError || !!error}
            />
            <Typography
              variant="caption"
              inline
              className="opacity7"
              color={addressError ? 'error' : 'default'}
            >
              {intl.formatMessage({
                id: addressError ? 'dapp.error.addresses' : 'dapp.paste',
              })}
            </Typography>
          </Grid>
          <Grid
            item
            container
            direction="column"
            xs="auto"
            className={classes.placeholders}
            spacing={16}
          >
            <Grid item xs="auto" container direction="column" spacing={8}>
              <Grid item>
                <Typography variant="caption" className="opacity7">
                  {intl.formatMessage({ id: 'general.application' })}
                </Typography>
              </Grid>
              <Grid item container spacing={8} alignItems="center">
                <Grid item>
                  {transferData.applicationId ? (
                    <AppImage
                      appId={transferData.applicationId}
                      appSize={30}
                      inline
                    />
                  ) : (
                    <div
                      style={{
                        width: 30,
                        height: 30,
                        backgroundColor: '#9A9A9A',
                        borderRadius: 5,
                      }}
                    />
                  )}
                </Grid>
                <Grid item>
                  {transferData.applicationId ? (
                    <Typography variant="subtitle1">
                      {getAppDisplayName(transferData.applicationId)}
                    </Typography>
                  ) : (
                    <div
                      style={{
                        width: 125,
                        height: 15,
                        backgroundColor: '#9A9A9A',
                      }}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs="auto" container direction="column" spacing={8}>
              <Grid item>
                <Typography variant="caption" className="opacity7">
                  {intl.formatMessage({ id: 'general.account' })}
                </Typography>
              </Grid>
              <Grid item container spacing={8} alignItems="center">
                <Grid item>
                  {transferData.accountId ? (
                    <RoboHash id={transferData.accountId} size={35} inline />
                  ) : (
                    <div
                      style={{
                        width: 35,
                        height: 35,
                        backgroundColor: '#9A9A9A',
                        borderRadius: '50%',
                      }}
                    />
                  )}
                </Grid>
                <Grid item>
                  {transferData.accountId ? (
                    <Typography variant="subtitle1">
                      {getFirstNLastN(transferData.accountId)}
                    </Typography>
                  ) : (
                    <div
                      style={{
                        width: 100,
                        height: 15,
                        backgroundColor: '#9A9A9A',
                      }}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          container
          justify="flex-start"
          alignItems="center"
          spacing={16}
          className={classes.formGrid}
        >
          <Grid item xs={6} sm>
            <TextField
              variant="outlined"
              label={intl.formatMessage({ id: 'general.amount' })}
              name="amount"
              onChange={handleAmountChange}
              value={transferData.amount || ''}
              fullWidth
            />
          </Grid>
          <Grid item xs={6} sm>
            <SelectAssetField
              options={account.assets}
              value={transferData.assetId}
              onChange={({ name, value }) => {
                onChange({
                  ...transferData,
                  [name]: value.toUpperCase(),
                })
              }}
            />
          </Grid>
          <Grid item xs sm>
            <WalletButton
              type="submit"
              disabled={
                account.isLoading.transferToApplication ||
                !transferData.applicationId ||
                !transferData.accountId ||
                addressError ||
                !transferData.amount ||
                !transferData.assetId ||
                busy ||
                !!error
              }
              busy={busy}
            >
              <div>
                {intl.formatMessage({ id: 'general.send' })}&nbsp;&nbsp;
              </div>
              <SendIcon height={20} width={20} />
            </WalletButton>
          </Grid>
        </Grid>
      </Grid>
      {snackbar && (
        <Toast
          message={intl.formatMessage({ id: 'transfers.transferSuccessful' })}
          close={() => setSnackbar()}
        />
      )}
      {confirm && (
        <TransferConfirmation
          handleClose={() => setConfirm(false)}
          handleConfirm={() => onConfirm()}
          applicationName={getAppDisplayName(transferData.applicationId)}
          applicationId={transferData.applicationId}
          accountId={transferData.accountId}
          amount={transferData.amount}
          asset={asset && asset.name}
        />
      )}
    </form>
  )
})

Transfer.propTypes = {
  onChange: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  transferData: PropTypes.shape({
    dappId: PropTypes.string,
    accountId: PropTypes.string,
    amount: PropTypes.string,
    assetId: PropTypes.string,
  }),
}

export default Transfer
