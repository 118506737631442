import { decorate, observable } from 'mobx'

class DappInfo {
  constructor(blockchain, isDefaultChain) {
    this.id = blockchain.id.toHex()
    this.name = blockchain.info.name
    this.chain = blockchain
    this.url = blockchain.info.website
    this.description = blockchain.info.description
    this.isDefaultChain = isDefaultChain
  }

  logo = null
}

export default decorate(DappInfo, {
  name: observable,
  url: observable,
  description: observable,
})
