import React from 'react'
import makeStyles from '@material-ui/styles/makeStyles'
import { Typography, Grid } from '@material-ui/core'
import { useIntl } from 'react-intl'
import TypeItem from './type-item'

const useStyles = makeStyles(theme => ({
  wrapper: {
    maxWidth: 600,
    margin: 'auto',
    marginTop: theme.spacing.unit * 10,
    [theme.breakpoints.down(500)]: {
      padding: `0 ${theme.spacing.unit * 1.5}px`,
    },
    [`${theme.breakpoints.down(700)} and (orientation: landscape)`]: {
      marginTop: 0,
    },
  },
  title: {
    [theme.breakpoints.down(600)]: {
      fontSize: 30,
    },
    fontSize: 50,
    fontFamily: theme.typography.titleFont,
  },
}))

const AccountType = ({ onContinue }) => {
  const classes = useStyles()
  const intl = useIntl()

  return (
    <div className={classes.wrapper}>
      <Typography
        variant="h3"
        paragraph
        align="center"
        className={classes.title}
      >
        {intl.formatMessage({ id: 'sign.up.type.title' })}
      </Typography>
      <Typography variant="subtitle1" align="center">
        {intl.formatMessage({ id: 'sign.up.type.subtitle' })}
      </Typography>
      <br />
      <br />
      <Grid container spacing={32}>
        <Grid item xs>
          <TypeItem
            name={intl.formatMessage({ id: 'sign.up.type.Custodial' })}
            feature={
              'With a custodial wallet, another party controls your private keys. In other words, you’re trusting a third party to secure your funds and return them if you want to trade or send them somewhere else. While a custodial wallet lessens personal responsibility, it requires trust in the custodian that holds your funds.'
            }
            onClick={() => onContinue(true)}
          />
        </Grid>
        <Grid item xs>
          <TypeItem
            name={intl.formatMessage({ id: 'sign.up.type.NonCustodial' })}
            feature={
              'With a non-custodial wallet, you have sole control of your private keys, which in turn control your cryptocurrency and prove the funds are yours. While there is no need to trust a third party when using a non-custodial wallet, this also means that you are solely responsible for not losing your keys and requires that you take your own precautions to protect your funds.'
            }
            onClick={() => onContinue(false)}
          />
        </Grid>
      </Grid>
    </div>
  )
}

export default AccountType
