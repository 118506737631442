import { decorate, observable } from 'mobx'
import DappAccounts from './dapp-accounts-store'
import DappAccount from './dapp-account-store'
import Dapp from './dapp-store'

class VaultAccount {
  constructor(id, name, mnemonic, ftAccount, session) {
    this.id = id
    this.name = name
    this.mnemonic = mnemonic
    this.keyPair = session.user.keyPair
    this.dappAccounts = new DappAccounts(
      new DappAccount(new Dapp(session, true), ftAccount),
      session,
    )
  }

  name = null
  mnemonic = null
  dappAccounts = null
  keyPair = null
  custodialLogin = false
  userAuthHash = null

  get publicKey() {
    return this.keyPair.pubKey
  }

  get privateKey() {
    return this.keyPair.privKey
  }
}

export default decorate(VaultAccount, {
  dappAccounts: observable,
})
