import React from 'react'
import { useIntl } from 'react-intl'
import { Redirect } from 'react-router'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/styles/makeStyles'
import { ReactComponent as AccountIcon } from '../../../resources/icons/Account.svg'
import { ReactComponent as PubKeyIcon } from '../../../resources/icons/PubKey.svg'
import { ReactComponent as ChainIcon } from '../../../resources/icons/Chain.svg'
import AuthorizeFormItem from './authorize-form-item'
import AppImage from '../../linked-apps/components/app-card/app-image'
import {
  Account,
  SingleSignatureAuthDescriptor,
  FlagsType,
  Blockchain,
} from 'ft3-lib'
import WalletButton from '../../../material/button'
import { loginCustodialWallet } from '../../../api/api'
import DirectoryService from '../../../lib/directory-service'
import { generateEncMsgForPublicKey } from '../../../lib/utils'

const useStyles = makeStyles(theme => ({
  wrapper: {
    paddingTop: 50,
    position: 'relative',
    margin: 'auto',
    maxWidth: 580,
    [`${theme.breakpoints.down(700)} and (orientation: landscape)`]: {
      paddingTop: 0,
    },
  },
  innerWrapper: {
    margin: '30px auto',
    padding: 30,
    backgroundColor: '#2E2E2E',
    width: '100%',
    borderRadius: 7,
  },
  logo: {
    height: 60,
    width: 60,
  },
  title: {
    [theme.breakpoints.down(600)]: {
      fontSize: 30,
    },
    fontSize: 50,
    fontFamily: theme.typography.titleFont,
  },
  dappName: {
    marginBottom: 0,
    lineHeight: 1.5,
  },
  dappText: {
    lineHeight: 1.5,
    fontSize: '0.9rem',
    opacity: 0.5,
  },
  button: {
    width: 200,
  },
}))

const AuthorizeForm = ({
  dappSession,
  dappAccount,
  dappId,
  vaultAccount,
  pubkey,
  successAction,
  cancelAction,
}) => {
  const [redirectLocation, setRedirectLocation] = React.useState()
  const [actionStatus, setActionStatus] = React.useState(null)
  const [error, setError] = React.useState('')
  const classes = useStyles()
  const intl = useIntl()

  const handleAction = (action, rawTx) => {
    if (!action || action === '' || action === 'close') {
      window.close()
    } else if (/^[a-zA-Z_-]+:\/\//.test(action)) {
      const url = rawTx ? `${action}?rawTx=${rawTx}` : action
      window.location.replace(url)
    } else if (/^\//.test(action)) {
      setRedirectLocation(action)
    } else {
      throw new Error('Unknown action')
    }
  }

  const authorizeWithDAppInfo = async () => {
    const encMsg = generateEncMsgForPublicKey(vaultAccount.userAuthHash, pubkey)

    const blockchain = await Blockchain.initialize(
      dappId,
      new DirectoryService(),
    )
    const account = await blockchain.query('ft3.get_account_by_id', {
      id: vaultAccount.id,
    })

    const loginResp = await loginCustodialWallet({
      name: vaultAccount.name,
      encMsg,
      dappInfo: {
        blockchainRID: dappId,
        accountRegistered: account != null,
      },
    })

    if (!loginResp.ok) {
      throw new Error('Error trying custodial authenticate')
    }

    const jsonResp = await loginResp.json()
    return jsonResp.addClientAuthDescriptorRawTx.toUpperCase()
  }

  const authorize = async () => {
    const authDescriptor = new SingleSignatureAuthDescriptor(
      pubkey.toBuffer(),
      [FlagsType.Transfer],
    )

    setError('')

    try {
      var proceedCallback = txn => {
        setActionStatus(intl.formatMessage({ id: 'authorize.submit.status' }))
        handleAction(successAction, txn)
      }

      if (vaultAccount.custodialLogin) {
        proceedCallback(await authorizeWithDAppInfo())
      } else {
        let rawTx

        if (!dappAccount) {
          rawTx = Account.rawTransactionRegister(
            dappSession.user,
            authDescriptor,
            dappSession.blockchain,
          )
        } else {
          rawTx = Account.rawTransactionAddAuthDescriptor(
            dappAccount.id,
            dappSession.user,
            authDescriptor,
            dappSession.blockchain,
          )
        }

        proceedCallback(rawTx.toHex())
      }
    } catch (error) {
      setError(intl.formatMessage({ id: 'authorize.error.failed' }))
    }
  }

  const handleCancelClick = () => {
    setActionStatus(intl.formatMessage({ id: 'authorize.cancel.status' }))
    handleAction(cancelAction || successAction)
  }

  return (
    <div className={classes.wrapper}>
      {redirectLocation && <Redirect to={redirectLocation} />}
      <Typography
        variant="h3"
        paragraph
        align="center"
        className={classes.title}
      >
        {intl.formatMessage(
          { id: 'authorize.title' },
          { dappName: dappSession && dappSession.blockchain.info.name },
        )}
      </Typography>
      <div className={classes.innerWrapper}>
        <Grid container direction="column" spacing={32}>
          <Grid
            item
            container
            justify="center"
            alignItems="flex-end"
            spacing={8}
          >
            <Grid item>
              <AppImage
                appId={dappSession && dappSession.blockchain.id.toHex()}
                userId={vaultAccount && vaultAccount.id}
                appSize={120}
                userSize={45}
              />
            </Grid>
            <Grid item>
              <Typography
                variant="subtitle1"
                paragraph
                className={classes.dappName}
              >
                {dappSession && dappSession.blockchain.info.name}
              </Typography>
              <Typography
                variant="subtitle1"
                paragraph
                className={classes.dappText}
              >
                by Chromaway
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs>
            <Typography variant="subtitle1" paragraph>
              {intl.formatMessage(
                { id: 'authorize.subtitle' },
                { dappName: dappSession && dappSession.blockchain.info.name },
              )}
            </Typography>
          </Grid>
          <Grid item xs="auto" container direction="column" spacing={24}>
            <AuthorizeFormItem
              title={intl.formatMessage({ id: 'general.application' })}
              text={dappSession && dappSession.blockchain.info.name}
              Icon={AccountIcon}
            />
            <AuthorizeFormItem
              title={intl.formatMessage(
                { id: 'general.id' },
                { field: intl.formatMessage({ id: 'general.account' }) },
              )}
              text={vaultAccount.id}
              Icon={PubKeyIcon}
            />
            <AuthorizeFormItem
              title={intl.formatMessage(
                { id: 'general.id' },
                { field: intl.formatMessage({ id: 'general.chain' }) },
              )}
              text={dappSession && dappSession.blockchain.id.toHex()}
              Icon={ChainIcon}
            />
            {error && (
              <Grid item>
                <Typography variant="subtitle1" color="error" paragraph>
                  {error}
                </Typography>
              </Grid>
            )}
          </Grid>
          <Grid
            item
            xs="auto"
            container
            direction="column"
            alignItems="center"
            spacing={24}
          >
            {actionStatus ? (
              <Grid item xs>
                <Typography variant="h5" paragraph>
                  {actionStatus}
                </Typography>
              </Grid>
            ) : (
              <>
                <Grid item xs>
                  <WalletButton
                    className={classes.button}
                    busy={false}
                    onClick={authorize}
                  >
                    {intl.formatMessage({ id: 'authorize.submit' })}
                  </WalletButton>
                </Grid>
                <Grid item xs>
                  <Button
                    onClick={handleCancelClick}
                    size="large"
                    className={classes.button}
                    color="primary"
                    disabled={false}
                  >
                    {intl.formatMessage({ id: 'general.cancel' })}
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

export default AuthorizeForm
