import DirectoryService from './directory-service'
import * as Config from '../config'
import { Blockchain } from 'ft3-lib'

export async function getBlockchains() {
  const directoryService = new DirectoryService()
  return new Promise(resolve => {
    Promise.all(
      directoryService.chainInfos.map(
        ({ chainId }) =>
          new Promise(resolve => {
            if (
              chainId.toString('hex').toUpperCase() ===
              Config.chainId.toUpperCase()
            ) {
              resolve(null)
            }
            Blockchain.initialize(chainId, directoryService)
              .then(blockchain => resolve(blockchain))
              .catch(() => resolve(null))
          }),
      ),
    ).then(chains => resolve(chains.filter(chain => chain)))
  })
}
