import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import makeStyles from '@material-ui/styles/makeStyles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Drawer from '@material-ui/core/Drawer'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import { ReactComponent as ProfileImage } from '../../../../resources/icons/Profile.svg'

const useStyles = makeStyles(theme => ({
  profileIcon: {
    background: '#4F4F4F',
    height: 45,
    width: 45,
    borderRadius: '50%',
    display: 'flex',
    '& svg': {
      margin: 'auto',
    },
  },
  drawerPaper: {
    width: 250,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    ...theme.mixins.toolbar,
    justifyContent: 'center',
  },
}))

const ProfileDrawer = ({ name, items }) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const intl = useIntl()

  const handleDrawerToggle = () => setOpen(open => !open)

  return (
    <>
      <div className={classes.profileIcon} onClick={handleDrawerToggle}>
        <ProfileImage />
      </div>
      <Drawer
        open={open}
        variant="temporary"
        anchor="right"
        onClose={handleDrawerToggle}
        classes={{ paper: classes.drawerPaper }}
      >
        <div className={classes.drawerHeader}>
          <Typography variant="h6">{name}</Typography>
        </div>
        <Divider />
        <List onClick={handleDrawerToggle}>
          {items.map(item =>
            typeof item.action === 'function' ? (
              <ListItem key={item.title} onClick={item.action}>
                <ListItemText
                  primary={intl.formatMessage({ id: item.title })}
                />
              </ListItem>
            ) : (
              <ListItem key={item.title} component={Link} to={item.action}>
                <ListItemText
                  primary={intl.formatMessage({ id: item.title })}
                />
              </ListItem>
            ),
          )}
        </List>
      </Drawer>
    </>
  )
}

export default ProfileDrawer
