import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import React, { useState } from 'react'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/styles'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { loginCustodialWallet } from '../../../api/api'
import { generateUserData } from '../../../lib/utils'
import WalletButton from '../../../material/button'
import { useObserver } from 'mobx-react-lite'
import accountStore from '../../../stores/accounts-store'

const useStyles = makeStyles(theme => ({
  form: {
    marginTop: theme.spacing.unit * 1,
  },
  forgot: {
    color: theme.palette.text.secondary,
    marginTop: theme.spacing.unit * 1,
  },
  list: {
    marginBottom: `${theme.spacing.unit * 3}px`,
    marginTop: `${theme.spacing.unit * 2}px`,
    borderRadius: 4,
    border: `1px solid ${theme.palette.borders}`,
  },
  actions: {
    textAlign: 'center',
    margin: `${theme.spacing.unit * 4}px auto 0`,
  },
  button: {
    maxWidth: 280,
    width: '100%',
  },
}))

const Custodial = () => {
  const classes = useStyles()
  const [error, setError] = useState('')
  const [password, setPassword] = useState('')
  const [email, setEmail] = React.useState('')
  const [busy, setBusy] = React.useState(false)
  const intl = useIntl()
  const accounts = React.useContext(accountStore)

  const scrollHookRef = React.useRef(null)

  React.useEffect(() => {
    setError('')
  }, [password, email]) // eslint-disable-line

  React.useEffect(() => {
    if (!error) {
      return
    }
    scrollHookRef.current.scrollIntoView()
  }, [error])

  const title = intl.formatMessage({
    id: 'sign.in.custodialTitle',
  })

  const signIn = async () => {
    setBusy(true)
    const { encMsg, keyPair, userAuthHash } = generateUserData(email, password)
    const resp = await loginCustodialWallet({
      name: email,
      encMsg,
    })
    const authData = await resp.json()
    if (!resp.ok) {
      setBusy(false)
      if (authData.message == null) {
        return setError('general.error')
      }

      if (typeof authData.message === 'string') {
        if (authData.message === 'Invalid userAuthHash') {
          return setError('sign.in.access.error.password')
        }
      }

      if (Array.isArray(authData.message)) {
        if (authData.message[0] === 'name should not be empty') {
          return setError('sign.in.access.error.no.email')
        }
      }
      return setError(authData.message)
    }
    await accounts.custodialLogin({ keyPair, authData, email, userAuthHash })
    setBusy(false)
  }

  return useObserver(() => (
    <>
      <Typography variant="subtitle1" align="center">
        {title}
      </Typography>
      <span ref={scrollHookRef} />
      {error && (
        <Typography color="error" align="center">
          {error}
        </Typography>
      )}
      <Grid container direction="column" spacing={24} className={classes.form}>
        <Grid item xs>
          <TextField
            variant="outlined"
            label={intl.formatMessage({ id: 'sign.in.email' })}
            type="email"
            fullWidth
            value={email || ''}
            onChange={e => setEmail(e.target.value)}
          />
        </Grid>
        <Grid item xs>
          <TextField
            variant="outlined"
            label={intl.formatMessage({ id: 'general.password' })}
            type="password"
            fullWidth
            name="repeatPassword"
            value={password || ''}
            onChange={e => setPassword(e.target.value)}
          />
        </Grid>
      </Grid>
      <Grid container justify="flex-end">
        <Link className={classes.forgot} to="reset-password">
          Forgot password?
        </Link>
      </Grid>
      <div className={classes.actions}>
        <WalletButton
          className={classes.button}
          onClick={signIn}
          disabled={busy || Boolean(error)}
        >
          {intl.formatMessage({ id: 'sign.in.access.submitText' })}
        </WalletButton>
      </div>
    </>
  ))
}

export default Custodial
