import React from 'react'
import * as PropTypes from 'prop-types'
import { Redirect } from 'react-router'
import { stringify } from 'query-string'

const QRHandlerSSO = ({ data, onSuccess }) => {
  onSuccess && onSuccess()
  return (
    <Redirect
      to={{
        pathname: '/authorize',
        search: stringify({ ...data, successAction: '/dashboard' }),
      }}
    />
  )
}

QRHandlerSSO.propTypes = {
  data: PropTypes.object.isRequired,
  onSuccess: PropTypes.func,
}

export default QRHandlerSSO
