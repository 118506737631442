const primary = {
  light: '#FF405E',
  main: '#FF405E',
  dark: '#D41A45',
}

const secondary = {
  light: '#9E5ECF',
  main: '#9E5ECF',
  dark: '#8048AB',
}

const error = {
  light: '#EB4521',
  main: '#EB4521',
  dark: '#EB4521',
}

const getPalette = type => {
  return {
    primary,
    secondary,
    error,
    info: { main: type === 'dark' ? '#FFB600' : '#9E5ECF' },
  }
}

export default getPalette
