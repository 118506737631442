import React from 'react'
import * as PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { parseQRString, QRType } from '../../lib/qr-util'
import QRHandlerSSO from './handlers/qr-handler-sso'
import QRHandlerTransfer from './handlers/qr-handler-transfer'

const QRHandler = ({ history, qrString, onSuccess, onFailure }) => {
  const { type, data } = parseQRString(qrString)

  switch (type) {
    case QRType.SSO:
      return <QRHandlerSSO data={data} onSuccess={onSuccess} />
    case QRType.Payment:
      return (
        <QRHandlerTransfer
          history={history}
          data={data}
          onSuccess={onSuccess}
        />
      )
    default:
      return null
  }
}

QRHandler.propTypes = {
  qrString: PropTypes.string.isRequired,
  onSuccess: PropTypes.func,
  onFailure: PropTypes.func,
}

export default withRouter(QRHandler)
