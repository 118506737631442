import * as bip39 from 'bip39'
import * as bip32 from 'bip32'
import { encrypt } from 'eciesjs'
import { op } from 'ft3-lib'
import { gtx, util } from 'postchain-client'
import Config from '../config'

Buffer.prototype.toHex = function() {
  return this.toString('hex').toUpperCase()
}

// eslint-disable-next-line
String.prototype.toBuffer = function() {
  if (!this.match('^[0-9a-fA-F]+$')) {
    throw new Error('toBuffer can be called only on a hex string')
  }

  return Buffer.from(this, 'hex')
}

export function generateMnemonic() {
  return bip39.generateMnemonic(160)
}

export function masterKeyNodeFromMnemonic(mnemonic) {
  const seed = bip39.mnemonicToSeedSync(mnemonic)
  return bip32.fromSeed(seed)
}

export function publicKeyFromMnemonic(mnemonic) {
  return masterKeyNodeFromMnemonic(mnemonic).publicKey
}

export function split(string) {
  if (!string) {
    return []
  }

  return string
    .split(' ')
    .map(component => component.trim())
    .filter(component => component.length > 0)
}

export function getFirstNLastN(value = '', n = 4) {
  return value.length > n * 2
    ? `${value.slice(0, n)}-${value.slice(value.length - n, value.length)}`
    : value
}

export function shuffle(a) {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    ;[a[i], a[j]] = [a[j], a[i]]
  }
  return a
}

export function partition(arr, fn) {
  return arr.reduce(
    (result, element) => {
      result[fn(element) ? 0 : 1].push(element)
      return result
    },
    [[], []],
  )
}

export async function asyncTimeout(timeout, callback) {
  return new Promise(resolve => {
    setTimeout(async () => {
      await callback()
      resolve()
    }, timeout)
  })
}

export function fromRawTransaction(rawTransaction, blockchain) {
  const deserializedTx = gtx.deserialize(rawTransaction)
  const txBuild = blockchain.transactionBuilder()

  // eslint-disable-next-line array-callback-return
  deserializedTx.operations.map(operation => {
    txBuild.add(op(operation.opName, ...operation.args))
  })
  const tx = txBuild.build(deserializedTx.signers)
  tx.tx.gtx.signatures = deserializedTx.signatures
  return tx
}

export const generateUserData = (email, password) => {
  const userAuthHash = util.hash256(`${email}:${password}`).toString('hex')
  const keyPair = util.makeKeyPair()
  const clientPubKey = keyPair.pubKey.toString('hex')

  const toEncrypt = JSON.stringify({
    userAuthHash,
    timestamp: Date.now(),
    clientPubKey,
  })

  const encMsg = encrypt(
    Config.IN_PUBLIC_KEY,
    Buffer.from(toEncrypt, 'utf8'),
  ).toString('hex')

  return {
    encMsg,
    keyPair,
    userAuthHash,
  }
}

export const generateEncMsgForPublicKey = (userAuthHash, pubKey) => {
  const toEncrypt = JSON.stringify({
    userAuthHash,
    timestamp: Date.now(),
    clientPubKey: pubKey.toUpperCase(),
  })

  return encrypt(Config.IN_PUBLIC_KEY, Buffer.from(toEncrypt, 'utf8')).toString(
    'hex',
  )
}
