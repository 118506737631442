import React from 'react'
import * as PropTypes from 'prop-types'
import QrReader from 'react-qr-reader'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  container: {
    top: 0,
    left: 0,
  },
  cameraFrameBorder: {
    stroke: '#9E5ECF',
    strokeWidth: 1,
    fill: 'none',
  },
  qrReader: {
    position: 'absolute',
    width: '100%',
    height: 'min-content',
    top: '50%',
    transform: 'translate(0, -50%)',
  },
  cameraFrame: {
    position: 'absolute',
    height: '100%',
    width: '100%',
  },
}))

const QRScanner = ({ legacyMode = false, onSuccess, onError }, ref) => {
  const classes = useStyles()
  const [qrString, setQRString] = React.useState()
  const readerRef = React.useRef()

  React.useImperativeHandle(ref, () => ({
    openImageDialog: () => {
      readerRef.current.openImageDialog()
    },
  }))

  React.useEffect(() => {
    qrString && onSuccess && onSuccess(qrString)
  }, [qrString]) // eslint-disable-line

  const handleError = error => {
    onError && onError(error)
  }

  const handleScan = data => {
    if (!data || qrString || data === qrString) {
      return
    }
    setQRString(data)
  }

  return (
    <div className={classes.container}>
      <QrReader
        ref={readerRef}
        delay={100}
        facingMode="environment"
        className={classes.qrReader}
        onError={handleError}
        onScan={handleScan}
        showViewFinder={false}
        legacyMode={legacyMode}
      />
      <div className={classes.cameraFrame}>
        <svg width="100%" height="100%" viewBox="0 0 100 100">
          <defs>
            <mask id="hole">
              <rect width="100" height="100" fill="white" />
              <rect x="12" y="12" height="76" width="76" fill="black" />
            </mask>
          </defs>
          <rect
            width="100"
            height="100"
            mask="url(#hole)"
            fillOpacity="0.8"
            fill="#1E191E"
          />
          <polyline
            points="12,30 12,12 30,12"
            className={classes.cameraFrameBorder}
          />
          <polyline
            points="70,12 88,12 88,30"
            className={classes.cameraFrameBorder}
          />
          <polyline
            points="12,70 12,88 30,88"
            className={classes.cameraFrameBorder}
          />
          <polyline
            points="88,70 88,88 70,88"
            className={classes.cameraFrameBorder}
          />
        </svg>
      </div>
    </div>
  )
}

QRScanner.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  size: PropTypes.number,
  legacyMode: PropTypes.bool,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
}

export default React.forwardRef(QRScanner)
