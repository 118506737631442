import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import accountsStore from '../../stores/accounts-store'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/styles'
import Typography from '@material-ui/core/Typography'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import AppImage from '../../pages/linked-apps/components/app-card/app-image'
import { getFirstNLastN } from '../../lib/utils'
import withWidth from '@material-ui/core/es/withWidth/withWidth'

const useStyles = makeStyles(theme => ({
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  contentRoot: {
    padding: `0 10px 10px`,
    [theme.breakpoints.down('xs')]: {
      margin: 0,
    },
  },
  closeButton: {
    color: 'white',
  },
  qrIcon: {
    marginRight: 10,
  },
}))

const AccountListDialog = ({ open, onClose, onSelectAccount, width }) => {
  const accounts = React.useContext(accountsStore)
  const classes = useStyles()

  const handleAccountClick = account => () => {
    onSelectAccount && onSelectAccount(account.dapp.id, account.id)
  }

  return (
    <Dialog
      open={open}
      aria-labelledby="account-list-dialog-title"
      maxWidth="xs"
      fullWidth
      fullScreen={width === 'xs'}
    >
      <div>
        <DialogTitle disableTypography className={classes.title}>
          <Typography variant="h6">Please select an account</Typography>
          {onClose ? (
            <IconButton className={classes.closeButton} onClick={onClose}>
              <CloseIcon />
            </IconButton>
          ) : null}
        </DialogTitle>
        <DialogContent className={classes.contentRoot}>
          <List>
            {accounts.current.dappAccounts.accounts.map(account => (
              <ListItem
                button
                key={`${account.dapp.id}:${account.id}`}
                onClick={handleAccountClick(account)}
              >
                <ListItemIcon>
                  <AppImage
                    appId={account.dapp.id}
                    userId={account.id}
                    appSize={40}
                    userSize={40}
                    inline
                  />
                </ListItemIcon>
                <ListItemText primary={getFirstNLastN(account.id)} />
              </ListItem>
            ))}
          </List>
        </DialogContent>
      </div>
    </Dialog>
  )
}

export default withWidth()(AccountListDialog)
