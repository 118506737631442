import './init'
import './fonts.css'
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider } from '@material-ui/styles'
import LangProvider from './lang-provider'
import theme from './resources/theme'
import * as serviceWorker from './serviceWorker'

const prefersDark = process.env.REACT_APP_THEME !== 'light'

ReactDOM.render(
  <BrowserRouter>
    <LangProvider>
      <ThemeProvider theme={theme(prefersDark ? 'dark' : 'light')}>
        <CssBaseline />
        <App />
      </ThemeProvider>
    </LangProvider>
  </BrowserRouter>,

  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

if (module.hot) {
  module.hot.accept()
}
