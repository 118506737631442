import React from 'react'
import AppBar from '@material-ui/core/AppBar'
import Typography from '@material-ui/core/Typography'

const EnvironmentTopBar = ({ message, textColor, backgroundColor }) => {
  return (
    <AppBar position="static">
      <div
        style={{
          backgroundColor: backgroundColor || '#CB92F0',
        }}
      >
        <Typography
          variant="subtitle1"
          component="p"
          style={{
            textAlign: 'center',
            color: textColor || '#1E191E',
          }}
        >
          {message}
        </Typography>
      </div>
    </AppBar>
  )
}

export default EnvironmentTopBar
