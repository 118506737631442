import { action, decorate, observable, computed } from 'mobx'
import DappInfo from './dapp-info-store'
import DappAccountInfo from './dapp-account-info-store'

class Dapp {
  constructor(session, isDefaultChain) {
    this.id = session.blockchain.id.toHex()
    this.session = session
    this.info = new DappInfo(session.blockchain, isDefaultChain)
    this.connectedChains = [this.info]
    this.isDefaultChain = isDefaultChain
    this.initialize()
  }

  get name() {
    return this.info.name
  }

  get description() {
    return this.info.description
  }

  get logo() {
    return null
  }

  get url() {
    return this.info.website
  }

  isLoading = false
  isInitialLoad = false
  accounts = []

  initialize = async () => {
    this.isInitialLoad = true
    try {
      await this.loadData()
    } finally {
      this.isInitialLoad = false
    }
  }

  loadData = async () => {
    this.isLoading = true
    try {
      const accounts = await this.session.getAccountsByAuthDescriptorId(
        this.session.user.authDescriptor.hash(),
      )
      accounts.forEach(account => {
        if (!this.accounts.some(({ id }) => account.id_.toHex() === id)) {
          this.accounts.push(
            new DappAccountInfo(account.id_.toHex(), this.info),
          )
        }
      })

      const chains = await this.session.blockchain.getLinkedChains()
      chains.forEach(chain => {
        if (!this.connectedChains.some(({ id }) => chain.id.toHex() === id)) {
          this.connectedChains.push(new DappInfo(chain, false))
        }
      })
    } finally {
      this.isLoading = false
    }
  }

  getByIdOrName = value => {
    const upperCasedValue = value.toUpperCase()

    return this.connectedChains.filter(
      chain =>
        chain.name.toUpperCase() === upperCasedValue ||
        chain.id === upperCasedValue,
    )
  }

  filterConnectedChains = value => {
    if (!value || value.length === 0) {
      return this.connectedChains
    }

    return this.connectedChains.filter(
      chain =>
        chain.name.toUpperCase().includes(value.toUpperCase()) ||
        chain.id.includes(value.toUpperCase()),
    )
  }
}

export default decorate(Dapp, {
  name: computed,
  description: computed,
  logo: computed,
  accounts: observable,
  connectedChains: observable,
  isLoading: observable,
  isInitialLoad: observable,
  loadData: action,
})
