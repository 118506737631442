import aes from 'browserify-aes'
import scrypt_async from 'scrypt-async'

const SALT_LENGTH = 16
const AUTH_TAG_LENGTH = 16
const IV_LENGTH = 12

function getRandomNumber(length) {
  return new Buffer(crypto.getRandomValues(new Uint8Array(length)))
}

function deriveKey(password, salt) {
  const passwordBuffer = Buffer.from(password.normalize('NFKD'), 'utf8')

  const params = {
    N: 16384,
    r: 8,
    p: 8,
    dkLen: 32,
    interruptStep: 10,
  }

  return new Promise(resolve => {
    scrypt_async(passwordBuffer, salt, params, resolve)
  })
}

function constructEncryptedSeed(buffer, authTag, salt, iv) {
  const result = Buffer.allocUnsafe(
    buffer.length + AUTH_TAG_LENGTH + SALT_LENGTH + IV_LENGTH,
  )

  buffer.copy(result, 0)
  authTag.copy(result, buffer.length)
  salt.copy(result, buffer.length + AUTH_TAG_LENGTH)
  iv.copy(result, buffer.length + AUTH_TAG_LENGTH + SALT_LENGTH)

  return result
}

function destructureEncryptedSeed(encryptedSeed) {
  let position = encryptedSeed.length - IV_LENGTH

  const iv = encryptedSeed.slice(position, position + IV_LENGTH)

  position -= SALT_LENGTH
  const salt = encryptedSeed.slice(position, position + SALT_LENGTH)

  position -= AUTH_TAG_LENGTH
  const authTag = encryptedSeed.slice(position, position + AUTH_TAG_LENGTH)

  const seed = encryptedSeed.slice(0, position)

  return [seed, authTag, salt, iv]
}

function aesEncrypt(buffer, key, iv) {
  const cipher = aes.createCipheriv('aes-256-gcm', key, iv)

  cipher.setAutoPadding(false)
  cipher.end(buffer)

  return [cipher.read(), cipher.getAuthTag()]
}

function aesDecrypt(buffer, authTag, key, iv) {
  const decipher = aes.createDecipheriv('aes-256-gcm', key, iv)

  decipher.setAutoPadding(false)
  decipher.setAuthTag(authTag)
  decipher.end(buffer)

  return decipher.read()
}

export async function encryptSeed(password, seed) {
  const iv = getRandomNumber(IV_LENGTH)
  const salt = getRandomNumber(SALT_LENGTH)
  const [cipherText, authTag] = aesEncrypt(
    Buffer.from(seed),
    await deriveKey(password, salt),
    iv,
  )

  return constructEncryptedSeed(cipherText, authTag, salt, iv).toString(
    'base64',
  )
}

export async function decryptSeed(password, encryptedSeed) {
  const buffer = Buffer.from(encryptedSeed, 'base64')
  const [cipherText, authTag, salt, iv] = destructureEncryptedSeed(buffer)
  const key = await deriveKey(password, salt)

  return aesDecrypt(cipherText, authTag, key, iv).toString()
}
