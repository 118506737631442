import Grid from '@material-ui/core/Grid'
import React from 'react'
import makeStyles from '@material-ui/styles/makeStyles'
import { Paper, Typography } from '@material-ui/core'
import { useIntl } from 'react-intl'
import WalletButton from '../../material/button'

const useStyles = makeStyles({
  root: {
    padding: 24,
    height: '100%',
  },
})

const TypeItem = ({ onClick, name, feature }) => {
  const classes = useStyles()
  const intl = useIntl()

  return (
    <Paper elevation={8} classes={{ root: classes.root }}>
      <Grid
        container
        justify="center"
        style={{ height: '100%' }}
        direction="column"
      >
        <Grid item>
          <Typography variant="h4" align="center">
            {name}
          </Typography>
        </Grid>
        <Grid item xs style={{ marginTop: 20, marginBottom: 20 }}>
          <Typography gutterBottom align="center">
            {feature}
          </Typography>
        </Grid>
        <Grid item>
          <WalletButton fullWidth onClick={onClick}>
            {intl.formatMessage(
              { id: 'sign.up.type.walletType' },
              {
                type: name,
              },
            )}
          </WalletButton>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default TypeItem
