import React from 'react'
import * as PropTypes from 'prop-types'
import IconButton from '@material-ui/core/IconButton'
import Snackbar from '@material-ui/core/Snackbar'
import CloseIcon from '@material-ui/icons/Close'

const contentProps = {
  'aria-describedby': 'toast-notification',
}

const Toast = props => {
  const {
    vertical = 'bottom',
    horizontal = 'right',
    duration = 3000,
    close,
    message,
  } = props

  const origin = { vertical, horizontal }

  const handleClose = (event, reason) => {
    if (reason !== 'clickaway') {
      close()
    }
  }

  return (
    <Snackbar
      open
      anchorOrigin={origin}
      autoHideDuration={duration}
      onClose={handleClose}
      ContentProps={contentProps}
      message={<span id={contentProps['aria-describedby']}>{message}</span>}
      action={
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      }
    />
  )
}

Toast.propTypes = {
  message: PropTypes.string.isRequired,
  close: PropTypes.func.isRequired,
  vertical: PropTypes.string,
  horizontal: PropTypes.string,
  duration: PropTypes.number,
}

export default Toast
