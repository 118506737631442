import { decorate, observable } from 'mobx'
import { createContext } from 'react'

class AppState {
  redirectAfterLogin = null
}

decorate(AppState, {
  redirectAfterLogin: observable,
})

export const appState = new AppState()

export default createContext(appState)
