module.exports = {
  explorerUrl: 'https://dev.explorer.chromia-development.com',
  canAddDapp: true,
  custodial: true,
  giveBalance: {
    amount: 200,
    asset: 'CHROMA',
  },
  environmentTopBar: {
    message: 'Development Environment',
    textColor: '#CB92F0',
    backgroundColor: '#434343',
  },
  crossChainTransfersEnabled: false,
  chainId: 'F7652A6890C094F1830D564BCED558EF401B6AACF787EBA6EEC0FC64EEA9F521',
  OUT_PUBLIC_KEY:
    '02d32eb147f1650d415e306d10a061c23d56d9241523734eca6dbac60fbe4059cb',
  IN_PUBLIC_KEY:
    '0355e239f2666877c93767863d368f8a3a999ad2ddd06b41ee4c173cd9a7ed44c8',
  CUSTODIAL_WALLET_ADDRESS: 'https://custodial-wallet.chromia.dev',
  IDP_ADDRESS: 'https://idp.chromia.dev',
  chains: [
    {
      rid: 'F7652A6890C094F1830D564BCED558EF401B6AACF787EBA6EEC0FC64EEA9F521',
      url: 'https://dev.vault-node.chromia-development.com',
    },
    // marketplace
    {
      rid: '251F94FAA72B62F3BEBC7D92D1DF78D0DC45C569D44DE410E03AF95393581BAF',
      url: 'https://dev.vault-node.chromia-development.com',
    },
    {
      rid: 'A1AD0D51DF619639915D812BAD426465B0D8E8EBB31F00C469BC453386A1186C',
      url:
        'https://0uukrttsal.execute-api.eu-west-1.amazonaws.com/chromia-sso-demo/',
    },
    // Chromunity
    {
      rid: '0A58CA98BBDD2716564F8ECB98FA4474867344B55E954CBD4D491F503AA0107A',
      url: 'https://cg7tt9fhch.execute-api.eu-central-1.amazonaws.com/dev/',
    },
    {
      rid: '66DF133A957C1E45D623DE64BE834300C716C6E380BE4FDF9800FF23C81E849A',
      url: 'https://dev.mod-node.chromia-development.com',
    },
    {
      // MoD test node
      rid: '5278a491b7737f230632d69131ec1cceb922521e29cb2af7acea90a8d7e5d1a4',
      url: 'https://0gn2fe3ep3.execute-api.eu-north-1.amazonaws.com/test/',
    },
    // MNA dev
    {
      rid: '649BD2C40CE2BC76FC892667659454762045AE140A8FB275085BE34A7AB049B8',
      url: 'https://cmnok3pov9.execute-api.eu-west-1.amazonaws.com/prod/',
    },
    // MNA dev vm1
    {
      rid: '28128A8D13F477520CB67B840C82C3D7967E2A36C43A7073AB91BCC4E622DED2',
      url: 'https://5xdhq04teh.execute-api.eu-central-1.amazonaws.com/dev/'
    },
    // MNA dev vm2
    {
      rid: 'B82F8E9FA069186899BC7EA96B956A55EC1D1521DFEE45ED4AD30980386BD2B9',
      url: 'https://3izgma8143.execute-api.eu-central-1.amazonaws.com/dev/'
    },
    // MNA dev vm3
    {
      rid: '6CC732D067934B11DCCA83F4EE85513EB2FC8611BBBBF3ECE2BE94B648706C95',
      url: 'https://0misrrvqpf.execute-api.eu-central-1.amazonaws.com/dev/'
    },
    // MNA dev vm4
    {
      rid: 'A2EFC4B19FF4D9B65A1281FA3CE593815EC8AA9AADB07D9161268DB4D45C1873',
      url: 'https://i6zvt2njyl.execute-api.eu-central-1.amazonaws.com/vm4/'
    },
    // MNA dev vm5
    {
      rid: '5A49E16AE79ADD079FFE707F48BF8E038D00B2582770FC09B17D323999EC7E10',
      url: 'https://1u5xeuq2a8.execute-api.eu-central-1.amazonaws.com/vm5/'
    },
    // MNA test
    {
      rid: '057FCE2FC716B954007943E73E239E0F49E589F14F2E4DB0DE725D7EE0825CCB',
      url: 'https://b7rg20ohzh.execute-api.eu-central-1.amazonaws.com/prod/',
    },
    // MNA Stage
    {
      rid: '25144240851EE2D381A0290A6E5EE6DB4E1B3BE6EA65A9445249ABA4C4B915EC',
      url: 'https://mm0259r4pf.execute-api.eu-central-1.amazonaws.com/prod/',
    
    },
    // MNA Steam release
    {
      rid: 'D0F6BFA36A08505A17637D387725A410ED5459DBB677297BC0DDF33F1580FA73',
      url: 'https://nbquz5sosl.execute-api.eu-central-1.amazonaws.com/prod/',
    },
    // MNA stress test node
    {
      rid: '82DF8E11D483116DBD1ACAF547995A4DA5534364E5AF48ADA4159BA9ECD39FD5',
      url: 'https://k5vkheuxq6.execute-api.us-east-1.amazonaws.com/prod/',
    },
    // Mysterious Seed
    {
      rid: '97c57d58795768ebd1fe5e88281a69f6d35be2be9ac5f0ade4955a0c1d454dc1',
      url: 'https://fn6qv2v7l3.execute-api.us-east-1.amazonaws.com/dev/',
    },
    // Planetary
    {
      rid: 'DCF77142C1E4606D24976AF4893E48F4E745F37A0D39756D94EEDD5E9BBEDA6A',
      url: 'https://dev.mod-node.chromia-development.com',
    },
    // Companion app
    {
      rid: 'A4CC648460CE83EA0F979F70AD95944A700F4B44728097341FF36B27FEA8AE41',
      url: 'https://ve50pr4i94.execute-api.eu-central-1.amazonaws.com/dev/',
    },
    // Filehub dev
    {
      rid: '38A6A080C33A36DC7D0EA5139284FE81BACB40AC3EE51EE187C575F0104195F0',
      url: 'https://gl90tpkjck.execute-api.eu-central-1.amazonaws.com/dev/',
    },
    // CloudSaga
    {
      rid: '75B858E74251F56FCCC2AF07F86F5FCFF2B6061A7FCB351FD4D651280485C7C6',
      url: 'https://r7tfsn1nml.execute-api.eu-central-1.amazonaws.com/'
    }
  ],
  dapps: [
    {
      name: 'Chromunity',
      url: 'https://dev.chromunity.com/',
      id: '0A58CA98BBDD2716564F8ECB98FA4474867344B55E954CBD4D491F503AA0107A'.toUpperCase(),
    },
    {
      name: 'SSO Demo',
      url: 'https://dev.sso.chromia-development.com/',
      id: 'A1AD0D51DF619639915D812BAD426465B0D8E8EBB31F00C469BC453386A1186C'.toUpperCase(),
    },
    {
      name: 'Companion App',
      url: 'alice://',
      id: 'A4CC648460CE83EA0F979F70AD95944A700F4B44728097341FF36B27FEA8AE41',
    },
  ],
}
