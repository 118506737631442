import { gtx } from 'postchain-client'

function validateFields(data, properties) {
  properties.forEach(property => {
    if (!data.hasOwnProperty(property)) {
      throw new Error(`Missing ${property}`)
    }
  })
}

function validate(type, data) {
  if (type === QRType.Payment) {
    validateFields(data, ['accountId', 'dappId'])
  } else if (type === QRType.SSO) {
    validateFields(data, ['accountId', 'dappId', 'pubkey'])
  }
}

export const QRType = {
  Payment: 'Payment',
  SSO: 'SSO',
}

export function qrString(type, data) {
  validate(type, data)
  return gtx.encodeValue({ type, data }).toString('hex')
}

export function parseQRStringType(qrType, qrString) {
  const { type, data } = gtx.decodeValue(qrString.toBuffer())
  if (type !== qrType) {
    throw new Error(`Expected ${qrType}, found ${type}`)
  }
  validate(type, data)
  return data
}

export function parseQRString(qrString) {
  const { type, data } = gtx.decodeValue(qrString.toBuffer())
  validate(type, data)
  return { type, data }
}

export function qrStringPayment(accountId) {
  return qrString(QRType.Payment, { accountId })
}
