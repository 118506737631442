import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import makeStyles from '@material-ui/styles/es/makeStyles'

const useStyles = makeStyles(theme => ({
  listIcon: {
    marginTop: 5,
  },
  listTitle: {
    marginBottom: 0,
    fontSize: '1.1rem',
  },
  listText: {
    marginBottom: 0,
    fontSize: '0.8rem',
    overflowWrap: 'break-word',
    maxWidth: 400,
  },
}))

const AuthroizeFormItem = ({ title, text, Icon }) => {
  const classes = useStyles()

  return (
    <Grid key={title} item container direction="row" spacing={24}>
      <Grid item xs="auto">
        <Icon className={classes.listIcon} />
      </Grid>
      <Grid item xs>
        <Typography variant="subtitle1" paragraph className={classes.listTitle}>
          {title}
        </Typography>
        <Typography variant="subtitle1" paragraph className={classes.listText}>
          {text}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default AuthroizeFormItem
