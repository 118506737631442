import { IconButton } from '@material-ui/core/index'
import React from 'react'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { observer } from 'mobx-react-lite'
import { IntlProvider } from 'react-intl'
import en from './resources/lang/en-US.json'
import fr from './resources/lang/fr.json'
import intlStore from './stores/intl-store'
import flagUrl from './resources/icons/gb.png'

const MESSAGES = {
  en,
  fr,
}

export const LanguageSelector = () => {
  const intlContext = React.useContext(intlStore)
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <IconButton
        aria-controls="lang-menu"
        aria-haspopup="true"
        style={{ color: 'white' }}
        onClick={handleClick}
      >
        <span
          style={{
            height: 24,
            width: 24,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <img src={flagUrl} alt="flag" width={24} />
        </span>
      </IconButton>
      <Menu
        id="lang-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {intlContext.options.map(option => (
          <MenuItem
            key={option.lang}
            onClick={() => {
              intlContext.changeLanguage(option.lang)
              handleClose()
            }}
          >
            <img
              src={flagUrl}
              alt="flag"
              width={20}
              style={{ marginRight: 8 }}
            />
            {`${option.label}`}
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}

const LangProvider = observer(({ children }) => {
  const intlContext = React.useContext(intlStore)

  return (
    <IntlProvider
      messages={MESSAGES[intlContext.lang]}
      locale={intlContext.lang}
      defaultLocale="gb"
    >
      {children}
    </IntlProvider>
  )
})

export default LangProvider
