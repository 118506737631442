import React, { useState, useContext } from 'react'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import makeStyles from '@material-ui/styles/makeStyles'
import { useIntl } from 'react-intl'
import WalletButton from '../../../material/button'
import accountsStore from '../../../stores/accounts-store'
import { split } from '../../../lib/utils'
import { validateMnemonic } from 'bip39'

const useStyles = makeStyles(theme => ({
  wrapper: {
    maxWidth: 600,
    margin: 'auto',
    marginTop: theme.spacing.unit * 10,
    [theme.breakpoints.down(500)]: {
      padding: `0 ${theme.spacing.unit * 1.5}px`,
    },
    [`${theme.breakpoints.down(700)} and (orientation: landscape)`]: {
      marginTop: 0,
    },
  },
  title: {
    [theme.breakpoints.down(600)]: {
      fontSize: 30,
    },
    fontSize: 50,
    fontFamily: theme.typography.titleFont,
  },
  seed: {
    marginTop: theme.spacing.unit * 6,
  },
  actions: {
    margin: `${theme.spacing.unit * 4}px 0`,
    textAlign: 'center',
  },
}))

const ImportMnemonic = ({ onContinue }) => {
  const classes = useStyles()
  const [mnemonic, setMnemonic] = useState()
  const [error, setError] = useState(null)
  const [continueDisabled, setContinueDisabled] = useState(true)
  const accounts = useContext(accountsStore)
  const intl = useIntl()

  const scrollHookRef = React.useRef(null)

  React.useEffect(() => {
    if (!error) {
      return
    }
    scrollHookRef.current.scrollIntoView()
  }, [error])

  const handleContinueClick = () => {
    if (!mnemonic) {
      setError(intl.formatMessage({ id: 'import.error.mnemonic' }))
      return
    }

    const wordCount = split(mnemonic).length
    if (wordCount !== 15) {
      setError(
        intl.formatMessage(
          { id: 'import.error.mnemonic.length' },
          { wordCount },
        ),
      )
      return
    }

    if (!validateMnemonic(mnemonic)) {
      return setError(
        intl.formatMessage({ id: 'import.error.mnemonic.invalid' }),
      )
    }

    onContinue && onContinue(mnemonic)
  }

  const handleMnemonicChange = ({ target: { value } }) => {
    setMnemonic(value)
    setError(null)

    setContinueDisabled(split(value || '').length !== 15)

    const account = accounts.findAccountByMnemonic(value)

    if (account) {
      setError(
        intl.formatMessage(
          { id: 'import.error.mnemonic.duplicate' },
          { name: account.name },
        ),
      )
      setContinueDisabled(true)
    }
  }

  const handleKeyDown = e => {
    if (e.keyCode === 13) {
      e.preventDefault()
      if (continueDisabled) return
      handleContinueClick()
    }
  }

  return (
    <div className={classes.wrapper}>
      <Typography
        variant="h3"
        paragraph
        align="center"
        className={classes.title}
      >
        {intl.formatMessage({ id: 'import.title' })}
      </Typography>
      <span ref={scrollHookRef} />
      {error && (
        <Typography variant="body1" paragraph color="error">
          {error}
        </Typography>
      )}
      <Typography variant="subtitle1" align="center">
        {intl.formatMessage({ id: 'import.subtitle' })}
      </Typography>
      <TextField
        variant="outlined"
        className={classes.seed}
        value={mnemonic || ''}
        label={intl.formatMessage({ id: 'import.seedLabel' })}
        name="mnemonic"
        rows={4}
        multiline
        fullWidth
        onChange={handleMnemonicChange}
        onKeyDown={handleKeyDown}
        autoFocus
      />
      <div className={classes.actions}>
        <WalletButton disabled={continueDisabled} onClick={handleContinueClick}>
          {intl.formatMessage({ id: 'import.submit' })}
        </WalletButton>
      </div>
    </div>
  )
}

export default ImportMnemonic
