import React, { useState } from 'react'
import makeStyles from '@material-ui/styles/makeStyles'
import MenuIcon from '@material-ui/icons/Menu'
import IconButton from '@material-ui/core/IconButton'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { useIntl } from 'react-intl'
import { NavLink } from 'react-router-dom'

const useStyles = makeStyles({
  drawerPaper: {
    width: 250,
  },
})

const MenuDrawer = ({ routes }) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const intl = useIntl()

  const handleDrawerToggle = () => setOpen(open => !open)

  return (
    <>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="end"
        onClick={handleDrawerToggle}
      >
        <MenuIcon />
      </IconButton>
      <Drawer
        open={open}
        variant="temporary"
        anchor="right"
        onClose={handleDrawerToggle}
        classes={{ paper: classes.drawerPaper }}
      >
        <List onClick={handleDrawerToggle}>
          {routes.map(
            ({ title, routeProps }) =>
              title && (
                <ListItem
                  key={routeProps.path}
                  component={NavLink}
                  to={routeProps.path}
                >
                  <ListItemText primary={intl.formatMessage({ id: title })} />
                </ListItem>
              ),
          )}
        </List>
      </Drawer>
    </>
  )
}

export default MenuDrawer
