import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles(() => ({
  button: {
    position: 'relative',
  },
  text: {
    opacity: ({ busy }) => (busy ? 0 : 1),
    display: 'flex',
    alignItems: 'center',
  },
  busyIndicator: {
    position: 'absolute',
    height: 24,
  },
}))

const WalletButton = ({
  children,
  color = 'primary',
  variant = 'contained',
  size = 'large',
  busy,
  className,
  disabled = busy,
  ...rest
}) => {
  const classes = useStyles({ busy })

  return (
    <Button
      size={size}
      color={color}
      variant={variant}
      disabled={disabled}
      className={`${classes.button} ${className ? className : ''}`}
      {...rest}
    >
      <div className={classes.text}>{children}</div>
      {busy && (
        <div className={classes.busyIndicator}>
          <CircularProgress color="inherit" size={20} />
        </div>
      )}
    </Button>
  )
}

export default WalletButton
