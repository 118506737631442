import React from 'react'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/styles'
import { useObserver } from 'mobx-react-lite'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { LanguageSelector } from '../../lang-provider'
import accountsStore from '../../stores/accounts-store'
import { publicRoutes } from '../../routes'
import { ReactComponent as LogoIcon } from '../../resources/icons/logo.svg'
import Profile from './components/profile/profile'
import ProfileDrawer from './components/profile/profile-drawer'
import MenuDrawer from './components/start-screen-menu/menu-drawer'
import MenuInline from './components/start-screen-menu/menu-inline'
import EnvirotnmentTopBar from '../../common/envitonment-top-bar/environment-top-bar'
import Config from '../../config'
import WalletButton from '../../material/button'
import { ReactComponent as QrCode } from '../../resources/icons/QrCode.svg'
import QRScannerDialog from '../../common/qr-scanner-dialog/qr-scanner-dialog'
import QRHandler from '../../common/qr-handler/qr-handler'
import { useLocation } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  toolbar: {
    background: '#1E191E',
  },
  logoWrapper: {
    position: 'absolute',
    left: 'calc(50% - 65px)',
    width: 130,
    '& svg': {
      height: 33,
      [theme.breakpoints.down(700)]: {
        height: 25,
      },
    },
    [theme.breakpoints.down('sm')]: {
      left: 0,
    },
  },
  logo: {
    height: '100%',
    width: '100%',
  },
  link: {
    [theme.breakpoints.down(700)]: {
      fontSize: '0.9rem',
    },
  },
  logoText: {
    fontSize: 11,
    textAlign: 'center',
    color: '#FFF8F8',
    fontWeight: 'bold',
    letterSpacing: 3,
  },
}))

const Header = () => {
  const accounts = React.useContext(accountsStore)
  const classes = useStyles()
  const [showQRScanner, setShowQRScanner] = React.useState(false)
  const [qrString, setQRString] = React.useState()
  const intl = useIntl()
  const location = useLocation()

  const profileItems = React.useMemo(
    () => {
      const items = [
        {
          title: 'header.profile.details',
          action: '/account-details',
        },
      ]
      if (!location.pathname.startsWith('/authorize')) {
        items[1] = {
          title: 'header.profile.logOut',
          action: accounts.logOut,
        }
      }
      return items
    },
    [location], // eslint-disable-line
  )

  const handleQRScan = data => {
    setShowQRScanner(false)
    setQRString(data)
  }

  const handleQRProcessingCompletion = () => {
    setQRString(null)
  }

  const handleSSOClick = () => {
    setShowQRScanner(true)
  }

  const handleCloseQRScannerDialog = () => {
    setShowQRScanner(false)
  }

  return useObserver(() => (
    <>
      {Config.environmentTopBar && (
        <EnvirotnmentTopBar {...Config.environmentTopBar} />
      )}
      <AppBar position="static" color="default">
        <Toolbar style={{ height: 96 }} className={classes.toolbar}>
          <Link
            to="/dashboard"
            className={classes.logoWrapper}
            style={{ textDecoration: 'inherit' }}
          >
            <LogoIcon className={classes.logo} />
            <Typography variant="body1" className={classes.logoText}>
              {intl.formatMessage({ id: 'general.vault' }).toUpperCase()}
            </Typography>
          </Link>
          {!accounts.current ? (
            <Grid
              container
              spacing={16}
              wrap="nowrap"
              justify="flex-end"
              alignItems="center"
            >
              <Hidden xsDown>
                <MenuInline routes={publicRoutes} />
              </Hidden>
              <Hidden smUp>
                <Grid item>
                  <MenuDrawer routes={publicRoutes} />
                </Grid>
              </Hidden>
              <Grid item>
                <LanguageSelector />
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={16} justify="flex-end" alignItems="center">
              <Hidden smDown>
                <Grid item>
                  <WalletButton color="secondary" onClick={handleSSOClick}>
                    <QrCode height={24} width={24} />
                    <div>
                      &nbsp;&nbsp;
                      {intl.formatMessage({ id: 'header.scanQrCode' })}
                    </div>
                  </WalletButton>
                </Grid>
              </Hidden>
              <Hidden mdUp>
                <Grid item>
                  <WalletButton color="secondary" onClick={handleSSOClick}>
                    <QrCode height={20} width={20} />
                  </WalletButton>
                </Grid>
              </Hidden>
              <Hidden xsDown>
                <Grid item>
                  <Profile name={accounts.current.name} items={profileItems} />
                </Grid>
              </Hidden>
              <Hidden smUp>
                <Grid item>
                  <ProfileDrawer
                    name={accounts.current.name}
                    items={profileItems}
                  />
                </Grid>
              </Hidden>
              <Grid item>
                <LanguageSelector />
              </Grid>
            </Grid>
          )}
        </Toolbar>
        <QRScannerDialog
          open={showQRScanner}
          title={intl.formatMessage({ id: 'header.scanQrCode' })}
          onClose={handleCloseQRScannerDialog}
          onSuccess={handleQRScan}
        />
        {qrString && (
          <QRHandler
            qrString={qrString}
            onSuccess={handleQRProcessingCompletion}
            onFailure={handleQRProcessingCompletion}
          />
        )}
      </AppBar>
    </>
  ))
}

export default Header
