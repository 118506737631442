import React, { useEffect, useState } from 'react'
import Avatar from '@material-ui/core/Avatar'
import FormHelperText from '@material-ui/core/FormHelperText'
import Typography from '@material-ui/core/Typography'
import Chip from '@material-ui/core/Chip'
import makeStyles from '@material-ui/styles/makeStyles'
import { useIntl } from 'react-intl'
import Reorder, { reorder } from 'react-reorder'
import WalletButton from '../../../material/button'

const useStyles = makeStyles(theme => ({
  wrapper: {
    maxWidth: 600,
    margin: 'auto',
    marginTop: theme.spacing.unit * 10,
    [theme.breakpoints.down(500)]: {
      padding: `0 ${theme.spacing.unit * 1.5}px`,
    },
  },
  actions: {
    margin: `${theme.spacing.unit}px 0`,
    textAlign: 'center',
  },
  title: {
    [theme.breakpoints.down(600)]: {
      fontSize: 30,
    },
    fontSize: 50,
    fontFamily: theme.typography.titleFont,
  },
  chip: {
    margin: theme.spacing.unit,
  },
  selected: {
    opacity: 0.6,
    borderStyle: 'dashed',
    '& span': {
      visibility: 'hidden',
    },
  },
  selectedComponentsContainer: {
    display: 'flex',
    margin: `${theme.spacing.unit * 6}px auto 0`,
    padding: theme.spacing.unit * 2,
    border: '1px dashed rgba(255, 255, 255, 0.12)',
    borderRadius: 5,
    minHeight: 130,
    maxWidth: 520,
    backgroundColor: theme.palette.background.contrast,
    '& .chip': {
      margin: `0 ${theme.spacing.unit / 2}px ${theme.spacing.unit / 2}px`,
    },
  },
  chipContainer: {
    maxWidth: 480,
    margin: `${theme.spacing.unit * 2}px auto 0`,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
}))

const VerifyMnemonic = ({ mnemonic, mnemonicComponents, onConfirm }) => {
  const [selectedComponentsIndexes, setSelectedComponentsIndexes] = useState([])
  const [busy, setBusy] = React.useState(false)
  const [error, setError] = React.useState()
  const intl = useIntl()

  const classes = useStyles()

  const scrollHookRef = React.useRef(null)

  useEffect(() => {
    if (!error) {
      return
    }
    scrollHookRef.current.scrollIntoView()
  }, [error])

  const handleChipClick = componentIndex => () => {
    if (selectedComponentsIndexes.includes(componentIndex)) return
    setSelectedComponentsIndexes([...selectedComponentsIndexes, componentIndex])
  }

  const handleChipDeleteClick = componentIndex => () => {
    setError(null)
    setSelectedComponentsIndexes(c => c.filter(c => c !== componentIndex))
  }

  const handleConfirmClick = async () => {
    if (
      mnemonicComponents.length !== selectedComponentsIndexes.length ||
      !onConfirm
    ) {
      return
    }

    setBusy(true)
    try {
      await onConfirm(selectedComponentsString())
    } catch (error) {
      setError(error.message)
    } finally {
      setBusy(false)
    }
  }

  const isSelected = componentIndex => {
    return selectedComponentsIndexes.includes(componentIndex)
  }

  const isValid = () => {
    return selectedComponentsString() === mnemonic
  }

  const isAllSelected = () => {
    return selectedComponentsIndexes.length === 15
  }

  const selectedComponentsString = () => {
    return selectedComponentsIndexes
      .map(index => mnemonicComponents[index])
      .join(' ')
  }

  const onReorder = (event, previousIndex, nextIndex) => {
    setError(null)
    setSelectedComponentsIndexes(
      reorder(selectedComponentsIndexes, previousIndex, nextIndex),
    )
  }

  return (
    <div className={classes.wrapper}>
      <Typography
        variant="h3"
        paragraph
        align="center"
        className={classes.title}
      >
        {intl.formatMessage({ id: 'sign.up.verifyMnemonic.title' })}
      </Typography>
      <Typography variant="subtitle1" align="center">
        {intl.formatMessage({ id: 'sign.up.verifyMnemonic.subtitle' })}
      </Typography>
      <span ref={scrollHookRef} />
      {error && <Typography color="error">{error}</Typography>}
      <div className={classes.selectedComponentsContainer}>
        {!selectedComponentsIndexes.length && (
          <Typography
            color="textSecondary"
            variant="caption"
            style={{ margin: 'auto', textAlign: 'center' }}
          >
            {intl.formatMessage({ id: 'sign.up.verifyMnemonic.guides' })}
          </Typography>
        )}
        <Reorder reorderId="mnemonic-list" onReorder={onReorder} holdTime={200}>
          {selectedComponentsIndexes.map((componentIndex, index) => (
            <Chip
              key={componentIndex}
              size="small"
              label={mnemonicComponents[componentIndex]}
              icon={<Avatar>{index + 1}</Avatar>}
              color="secondary"
              className="chip"
              onDelete={handleChipDeleteClick(componentIndex)}
            />
          ))}
        </Reorder>
      </div>
      <div className={classes.actions}>
        {isAllSelected() && !isValid() && (
          <FormHelperText error component="span">
            {intl.formatMessage({ id: 'sign.up.verifyMnemonic.error.order' })}
          </FormHelperText>
        )}
      </div>
      <div className={classes.chipContainer}>
        {mnemonicComponents.map((component, index) => (
          <Chip
            key={index}
            label={component}
            color="default"
            variant="outlined"
            clickable={!isSelected(index)}
            className={`${classes.chip} ${isSelected(index) &&
              classes.selected}`}
            onClick={handleChipClick(index)}
          />
        ))}
      </div>
      <div className={classes.actions}>
        <WalletButton
          disabled={!isAllSelected() || !isValid() || busy}
          busy={busy}
          onClick={handleConfirmClick}
        >
          {intl.formatMessage({ id: 'sign.up.verifyMnemonic.submit' })}
        </WalletButton>
      </div>
    </div>
  )
}

export default VerifyMnemonic
