import React from 'react'
import * as PropTypes from 'prop-types'
import ErrorIcon from '@material-ui/icons/Error'
import Typography from '@material-ui/core/Typography'

const ErrorLabel = ({ text }) => (
  <div style={{ textAlign: 'center' }}>
    <ErrorIcon fontSize="large" color="error" />
    <Typography variant="h6">{text}</Typography>
  </div>
)

ErrorLabel.propTypes = {
  text: PropTypes.string.isRequired,
}

export default ErrorLabel
