import React from 'react'
import * as PropTypes from 'prop-types'
import makeStyles from '@material-ui/styles/makeStyles'

const useStyles = makeStyles(theme => ({
  root: {
    background: '#2F4968',
    borderRadius: '50%',
    overflow: 'hidden',
    height: ({ size }) => size,
    width: ({ size }) => size,
  },
}))

const RoboHash = props => {
  const { root } = useStyles(props)
  const dimensions = `${props.size}x${props.size}`

  return (
    <div className={root}>
      <img
        src={`https://robohash.org/set_set1/${props.id}?size=${dimensions}`}
        alt="robohash"
      />
    </div>
  )
}

RoboHash.propTypes = {
  id: PropTypes.string.isRequired,
  size: PropTypes.number,
}

export default RoboHash
