import { masterKeyNodeFromMnemonic } from '../lib/utils'
import { decryptSeed, encryptSeed } from '../lib/crypto'
import { action, decorate, observable } from 'mobx'
import { createContext } from 'react'
import VaultAccount from './vault-account-store'
import DirectoryService from '../lib/directory-service'
import {
  SingleSignatureAuthDescriptor,
  User,
  Blockchain,
  FlagsType,
  PaymentHistorySyncManager,
  PaymentHistoryStoreLocalStorage,
} from 'ft3-lib'
import Config from '../config'
import { giveBalanceIfNeeded } from '../lib/dev-helpers'

PaymentHistorySyncManager.defaultPaymentHistoryStore = new PaymentHistoryStoreLocalStorage()

export class Accounts {
  constructor() {
    this.loadAccounts()
  }

  current = null
  accounts = []
  blockchain = null

  connectToBlockchainIfNeeded = async () => {
    if (this.blockchain) {
      return
    }

    this.blockchain = await Blockchain.initialize(
      Config.chainId.toBuffer(),
      new DirectoryService(),
    )
  }

  userFrom = masterKeyNode => {
    const keyPair = {
      privKey: masterKeyNode.privateKey,
      pubKey: masterKeyNode.publicKey,
    }

    return new User(
      keyPair,
      new SingleSignatureAuthDescriptor(keyPair.pubKey, [
        FlagsType.Account,
        FlagsType.Transfer,
      ]),
    )
  }

  failedAuth = () => {
    this.logOut()
  }

  logOut = () => {
    this.current = null
  }

  login = async (password, accountId, retryCount = 1) => {
    try {
      await this.connectToBlockchainIfNeeded()
    } catch {
      if (retryCount > 0) {
        await this.login(password, accountId, retryCount - 1)
      } else {
        throw new Error('Not connected to the blockchain')
      }
      return
    }

    const account = this.accounts.find(({ id }) => id === accountId)
    if (!account) {
      return
    }

    const mnemonic = await decryptSeed(password, account.encryptedSeed)
    const masterKeyNode = masterKeyNodeFromMnemonic(mnemonic)

    const user = this.userFrom(masterKeyNode)
    const session = this.blockchain.newSession(user)
    const ftAccount = await session.getAccountById(accountId.toBuffer())

    this.current = new VaultAccount(
      accountId,
      account.name,
      mnemonic,
      ftAccount,
      session,
    )
  }

  custodialLogin = async ({ keyPair, authData, email, userAuthHash }) => {
    await this.connectToBlockchainIfNeeded()

    const authDescriptor = new SingleSignatureAuthDescriptor(keyPair.pubKey, [
      FlagsType.Account,
      FlagsType.Transfer,
    ])

    const user = new User(keyPair, authDescriptor)

    const session = this.blockchain.newSession(user)
    const account = await session.getAccountById(
      authData.registrationRecord.accountId.toBuffer(),
    )

    this.current = new VaultAccount(
      authData.registrationRecord.accountId,
      email,
      '',
      account,
      session,
    )

    this.current.custodialLogin = true
    this.current.userAuthHash = userAuthHash
  }

  addAccountAndLogin = async (
    accountName,
    mnemonic,
    password,
    retryCount = 1,
  ) => {
    try {
      await this.connectToBlockchainIfNeeded()
    } catch {
      if (retryCount > 0) {
        await this.addAccountAndLogin(
          accountName,
          mnemonic,
          password,
          retryCount - 1,
        )
      } else {
        throw new Error('Not connected to the blockchain')
      }
      return
    }

    const encryptedSeed = await encryptSeed(password, mnemonic)
    const masterKeyNode = masterKeyNodeFromMnemonic(mnemonic)

    const user = this.userFrom(masterKeyNode)
    const ftAccount = await this.registerAccountIfNeeded(user)
    const session = this.blockchain.newSession(user)

    this.addAccount({
      id: ftAccount.id_.toHex(),
      name: accountName,
      encryptedSeed,
    })

    this.current = new VaultAccount(
      ftAccount.id_.toHex(),
      accountName,
      mnemonic,
      ftAccount,
      session,
    )
  }

  hasAccount = accountName => {
    return this.accounts.some(({ name }) => accountName === name)
  }

  findAccountByMnemonic = mnemonic => {
    const user = this.userFrom(masterKeyNodeFromMnemonic(mnemonic))
    return this.accounts.find(({ id }) => id === user.authDescriptor.id.toHex())
  }

  registerAccountIfNeeded = async user => {
    const accounts = await this.blockchain.getAccountsByParticipantId(
      user.keyPair.pubKey,
      user,
    )

    if (accounts.length > 0) {
      return accounts[0]
    }

    const account = await this.blockchain.registerAccount(
      user.authDescriptor,
      user,
    )

    await giveBalanceIfNeeded(account, this.blockchain)

    await account.sync()
    return account
  }

  addAccount = account => {
    //TODO: throw error if account invalid
    //load accounts first. if an account was created in other tab, then storing
    //accounts without loading them first would store current state which doesn't
    //have the account created in other tab
    this.loadAccounts()
    this.accounts.push(account)
    this.storeAccounts(this.accounts)
  }

  deleteAccount = accountId => {
    PaymentHistorySyncManager.deleteAccountPaymentHistory(accountId.toBuffer())
    this.accounts = this.accounts.filter(({ id }) => id !== accountId)
    this.storeAccounts(this.accounts)
  }

  storeAccounts = accounts => {
    localStorage.setItem('accounts', JSON.stringify(accounts))
  }

  loadAccounts = () => {
    this.accounts = JSON.parse(localStorage.getItem('accounts')) || []
  }
}

decorate(Accounts, {
  current: observable,
  accounts: observable,
  successAuth: action,
  failedAuth: action,
  logOut: action,
  custodialLogin: action,
  addAccount: action,
  addAccountAndLogin: action,
})

export const accounts = new Accounts()
window.accounts = accounts

export default createContext(accounts)
