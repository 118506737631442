import React from 'react'
import Hidden from '@material-ui/core/Hidden'
import { observer } from 'mobx-react-lite'
import { parse, stringify } from 'query-string'
import { useIntl } from 'react-intl'
import { Link, Redirect } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/styles/makeStyles'
import MuiLink from '@material-ui/core/Link'
import Config from '../../config'
import accountsStore from '../../stores/accounts-store'
import CenterComponent from '../../common/center-component/center-component'
import { ReactComponent as LeftShapes } from '../../resources/icons/LeftShapes.svg'
import { ReactComponent as RightShapes } from '../../resources/icons/RightShapes.svg'
import AccountCard from '../linked-apps/components/app-card/account-card'
import DirectoryService from '../../lib/directory-service'
import ManageCustomDapp from './custom-dapp/custom-dapp-modal'
import useLocalStorage from '../../hooks/local-storage'

const useStyles = makeStyles(theme => ({
  title: {
    [theme.breakpoints.down(600)]: {
      fontSize: 26,
    },
    fontSize: 40,
    fontFamily: theme.typography.titleFont,
  },
  innerWrapper: {
    position: 'relative',
    height: 280,
    [theme.breakpoints.down(500)]: {
      padding: `0 ${theme.spacing.unit * 1.5}px`,
    },
    [`${theme.breakpoints.down(700)} and (orientation: landscape)`]: {
      height: 80,
    },
  },
  leftShapes: {
    color: theme.palette.text.primary,
    position: 'absolute',
    left: 0,
  },
  rightShapes: {
    color: theme.palette.text.primary,
    position: 'absolute',
    right: 0,
  },
  infoColor: {
    color: theme.palette.info.main,
  },
}))

const Dashboard = observer(({ location, location: { search } }) => {
  const classes = useStyles()
  const accounts = React.useContext(accountsStore)
  const [managing, setManaging] = React.useState(false)
  const [customDapps, setCustomDapps] = useLocalStorage('custom-dapps', [])
  const intl = useIntl()

  const [chromiaAccounts] = React.useState(() =>
    accounts.current.dappAccounts.accounts.filter(
      i => i.dapp.isDefaultChain === true,
    ),
  )

  const query = search && parse(search)
  const dapps = new DirectoryService().getDapps().map(dapp => ({ dapp }))

  const handleAddDapp = dapp => {
    setCustomDapps([...customDapps, dapp])
  }

  const handleEditDapp = (updated, dapp) => {
    setCustomDapps(
      customDapps.map(d => {
        if (d.rid !== dapp.rid) return d
        return Object.assign({}, d, updated)
      }),
    )
  }

  const handleRemoveDapp = dapp => {
    setCustomDapps(customDapps.filter(({ rid }) => rid !== dapp.rid))
  }

  if (query && query.route) {
    const { route, ...withoutRoute } = query
    return (
      <Redirect
        to={{
          ...location,
          pathname: route,
          search: stringify(withoutRoute),
        }}
      />
    )
  }

  return (
    <div style={{ marginBottom: 100 }}>
      <Grid
        container
        alignItems="center"
        justify="center"
        className={classes.innerWrapper}
      >
        <Typography
          variant="h3"
          paragraph
          align="center"
          className={classes.title}
        >
          {intl.formatMessage({ id: 'dashboard.welcome' })}
        </Typography>
        <Hidden smDown>
          <LeftShapes className={classes.leftShapes} />
          <RightShapes className={classes.rightShapes} />
        </Hidden>
      </Grid>
      <div>
        {accounts.current.dappAccounts.isLoading ? (
          <CenterComponent>
            <CircularProgress />
          </CenterComponent>
        ) : (
          <>
            <Typography variant="h6">
              {intl.formatMessage({ id: 'dashboard.account' })}
            </Typography>
            <Divider />
            <br />
            <br />
            <Grid container spacing={16}>
              {chromiaAccounts.map(account => (
                <Grid item key={account.id} xs={12} sm={4} md={4} lg={3}>
                  <Link
                    to={`/dashboard/${account.dapp.id}/${account.id}`}
                    style={{ textDecoration: 'inherit' }}
                  >
                    <AccountCard account={account} />
                  </Link>
                </Grid>
              ))}
            </Grid>
            <>
              <br />
              <br />
              <Typography variant="h6">
                {intl.formatMessage({ id: 'dashboard.dappAccounts' })}
              </Typography>
              <Divider />
              <br />
              <br />

              <Grid container spacing={16}>
                {accounts.current.dappAccounts.accounts.length ? (
                  accounts.current.dappAccounts.accounts.map(account =>
                    !account.dapp.isDefaultChain ? (
                      <Grid
                        item
                        key={`${account.dapp.id};${account.id}`}
                        xs={12}
                        sm={4}
                        md={4}
                        lg={3}
                      >
                        <Link
                          to={`/dashboard/${account.dapp.id}/${account.id}`}
                          style={{ textDecoration: 'inherit' }}
                        >
                          <AccountCard account={account} />
                        </Link>
                      </Grid>
                    ) : null,
                  )
                ) : (
                  <Typography
                    style={{ marginLeft: 20 }}
                    variant="caption"
                    color="textSecondary"
                  >
                    {intl.formatMessage({ id: 'dashboard.noDapp' })}
                  </Typography>
                )}
              </Grid>
            </>

            <>
              <br />
              <br />
              <Grid container justify="space-between">
                <Grid item xs>
                  <Typography variant="h6">
                    {intl.formatMessage({ id: 'dashboard.allAccounts' })}
                  </Typography>
                </Grid>
                {Config.canAddDapp && (
                  <Grid item xs="auto">
                    <div onClick={() => {}}>
                      <Grid container alignItems="center">
                        <MuiLink
                          underline="none"
                          variant="body1"
                          className={classes.infoColor}
                          onClick={() => setManaging(null)}
                        >
                          {intl.formatMessage({ id: 'dashboard.addDapp' })}
                        </MuiLink>
                      </Grid>
                    </div>
                  </Grid>
                )}
              </Grid>
              <Divider />
              <br />
              <br />
              <Grid container spacing={16}>
                {dapps.map(account => (
                  <Grid item xs={12} sm={4} md={4} lg={3} key={account.dapp.id}>
                    <a
                      href={account.dapp.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <AccountCard account={account} />
                    </a>
                  </Grid>
                ))}
                {customDapps.map(dapp => (
                  <Grid item xs={12} sm={4} md={4} lg={3} key={dapp.rid}>
                    <a
                      href={dapp.web}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <AccountCard
                        account={{
                          dapp: {
                            custom: true,
                            name: dapp.name,
                            id: dapp.rid,
                            url: dapp.web,
                          },
                        }}
                        onEditClick={() => setManaging(dapp)}
                      />
                    </a>
                  </Grid>
                ))}
              </Grid>
            </>
          </>
        )}
      </div>
      {managing !== false && (
        <ManageCustomDapp
          dapp={managing}
          onAdd={handleAddDapp}
          onEdit={handleEditDapp}
          onRemove={handleRemoveDapp}
          handleClose={() => setManaging(false)}
        />
      )}
    </div>
  )
})
export default Dashboard
