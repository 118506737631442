import { makeStyles } from '@material-ui/styles'
import React from 'react'
import Layout from './layout/layout'

const useStyles = makeStyles({
  '@global': {
    html: {
      height: '100%',
    },
    body: {
      fontFamily: '"NB-International", "Roboto", "Helvetica", sans-serif',
      height: '100%',
    },
    '#root': {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    '@keyframes pulse': {
      '0%': {
        transform: 'scale(0.5)',
      },
      '100%': {
        transform: 'scale(1)',
      },
    },
  },
})

const App = () => {
  useStyles()
  return <Layout />
}

export default App
