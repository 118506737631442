import { partition } from './lib/utils'
import Dashboard from './pages/dashboard/dashboard'
import Details from './pages/dashboard/details/details'
import Import from './pages/import/import'
import ResetPassword from './pages/reset-password/reset-password'
import SignIn from './pages/sign-in/sign-in'
import SignUp from './pages/sign-up/sign-up'
import AccountDetails from './pages/account-details/account-details'
import LinkedApps from './pages/linked-apps/linked-apps'
import AuthorizeDapp from './pages/authorize-dapp/authorize'
import { ReactComponent as AccountDetailsIcon } from '../src/resources/icons/account-details.svg'
import { ReactComponent as LinkedAppsIcon } from '../src/resources/icons/linked-apps.svg'

const routes = [
  {
    title: 'header.importAccount',
    Component: Import,
    authGuard: false,
    routeProps: {
      path: '/import-account/',
      exact: true,
    },
  },
  {
    title: 'header.logIn',
    Component: SignIn,
    authGuard: false,
    routeProps: {
      path: '/',
      exact: true,
    },
  },
  {
    title: 'header.signUp',
    Component: SignUp,
    authGuard: false,
    routeProps: {
      path: '/create-account/',
      exact: true,
    },
  },
  {
    Component: ResetPassword,
    authGuard: false,
    routeProps: {
      path: '/reset-password/',
      exact: true,
    },
  },
  {
    title: 'header.dashboard',
    Component: Dashboard,
    authGuard: true,
    routeProps: {
      path: '/dashboard/',
      exact: true,
    },
  },
  {
    title: 'header.details',
    Component: Details,
    authGuard: true,
    routeProps: {
      path: '/dashboard/:dappId/:accountId/',
      exact: true,
    },
  },
  {
    title: 'header.linkedApps',
    Component: LinkedApps,
    Icon: LinkedAppsIcon,
    authGuard: true,
    routeProps: {
      path: '/linked-apps/',
      exact: true,
    },
  },
  {
    title: 'header.accountDetails',
    Component: AccountDetails,
    Icon: AccountDetailsIcon,
    authGuard: true,
    routeProps: {
      path: '/account-details/',
      exact: true,
    },
  },
  {
    title: 'header.authorizeApp',
    Component: AuthorizeDapp,
    authGuard: true,
    routeProps: {
      path: '/authorize/',
      exact: true,
    },
  },
]

const isProtected = route => route.authGuard

export const [protectedRoutes, publicRoutes] = partition(routes, isProtected)

export default routes
