export function validate(valueValidators, callback) {
  for (const valueValidator of valueValidators) {
    if (valueValidator.length < 2) {
      continue
    }
    const [value, validators] = valueValidator
    for (const validator of validators) {
      const errorMessage = validator(value)
      if (errorMessage) {
        callback && callback(errorMessage)
        return false
      }
    }
  }

  return true
}

export const required = error => value => {
  return value && value.length > 0 ? null : error
}

export const accountId = error => value => {
  return value && value.match('^[0-9a-fA-F]{64}$') ? null : error
}

export const lessOrEqual = (value1, error) => value => {
  if (!value) return error
  return value1 >= value ? null : error
}

export const isInteger = error => value => {
  return Number.isInteger(value) ? null : error
}

export const greaterThan = (value1, error) => value => {
  if (!value) return error
  return value > value1 ? null : error
}

export const equalValues = (value1, error) => value => {
  return value1 === value ? null : error
}

export const minLength = (num, error) => value => {
  if (!value) return error
  return value.length >= num ? null : error
}
