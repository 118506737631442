import Hidden from '@material-ui/core/Hidden'
import TextField from '@material-ui/core/TextField'
import React from 'react'
import * as PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { useIntl } from 'react-intl'
import Toast from '../../../common/toast/toast'
import { ReactComponent as LinkChain } from '../../../resources/icons/LinkChain.svg'
import SelectAssetField from './select-asset-field'
import QRCode from 'qrcode.react'
import { qrString, QRType } from '../../../lib/qr-util'
import { copyToClipboard } from '../../../common/clipboard/clipboard'

const QR_SIZE = 150
const useStyles = makeStyles(theme => ({
  address: {
    background: theme.palette.background.secondary,
    wordBreak: 'break-all',
    borderRadius: theme.spacing.unit / 2,
    '& p': {
      padding: theme.spacing.unit * 2,
    },
  },
  clipboard: {
    cursor: 'pointer',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
      marginTop: theme.spacing.unit,
    },
  },
  mainContainer: {
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
      alignItems: 'center',
    },
  },
  flexItem: {
    [theme.breakpoints.down('xs')]: {
      flexBasis: 'auto',
    },
  },
  formContainer: {
    marginTop: 10,
    background: theme.palette.background.confirmationBg,
  },
}))

const Receive = ({ account, onChange }) => {
  const [snackbar, setSnackbar] = React.useState()
  const classes = useStyles()
  const [receiveData, setReceiveData] = React.useState({
    accountId: account.id,
    dappId: account.dapp.id,
  })
  const intl = useIntl()

  React.useEffect(() => {
    onChange && onChange(qrString(QRType.Payment, receiveData))
  }, [receiveData]) // eslint-disable-line

  const copy = () => {
    copyToClipboard(`${account.dapp.id};${account.id}`)
    setSnackbar(true)
  }

  const handleAssetChange = ({ value }) => {
    if (value && value.length > 0) {
      setReceiveData(receiveData => ({ ...receiveData, assetId: value }))
    } else {
      setReceiveData(({ assetId, ...rest }) => rest)
    }
  }

  const handleAmountChange = ({ target: { value } }) => {
    if (value && value.length > 0) {
      setReceiveData(receiveData => ({ ...receiveData, amount: value }))
    } else {
      setReceiveData(({ amount, ...rest }) => rest)
    }
  }

  return (
    <Grid container spacing={24}>
      <Grid item container spacing={24} className={classes.mainContainer}>
        <Grid
          className={classes.flexItem}
          item
          xs
          container
          direction="column"
          justify="space-around"
        >
          <Grid item className={classes.address}>
            <Typography>
              {account.dapp.id};{account.id}
            </Typography>
          </Grid>
          <Grid
            item
            onClick={copy}
            container
            alignItems="center"
            spacing={8}
            className={classes.clipboard}
          >
            <Grid item>
              <LinkChain width={18} height={18} />
            </Grid>
            <Grid item>
              <Typography variant="caption" inline className="opacity7">
                {intl.formatMessage({ id: 'dapp.clickToCopy' })}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid className={classes.flexItem} item xs="auto">
          <QRCode
            value={qrString(QRType.Payment, receiveData)}
            size={QR_SIZE}
            includeMargin={true}
          />
        </Grid>
      </Grid>
      <Grid
        item
        container
        spacing={16}
        className={classes.formContainer}
        alignItems="center"
      >
        <Grid item xs>
          <TextField
            variant="outlined"
            label={intl.formatMessage({ id: 'general.amount' })}
            name="amount"
            onChange={handleAmountChange}
            value={receiveData.amount || ''}
            fullWidth
          />
        </Grid>
        <Grid item xs>
          <SelectAssetField
            options={account.assets}
            value={receiveData.assetId || ''}
            onChange={handleAssetChange}
          />
        </Grid>
        <Hidden xsDown>
          <Grid style={{ width: QR_SIZE }} />
        </Hidden>
        {snackbar && (
          <Toast
            message={intl.formatMessage(
              { id: 'general.clipboard' },
              {
                field: intl.formatMessage({
                  id: 'general.address',
                }),
              },
            )}
            close={() => setSnackbar()}
          />
        )}
      </Grid>
    </Grid>
  )
}

Receive.propTypes = {
  account: PropTypes.object,
}

export default Receive
