import React, { useContext, useState } from 'react'
import { useIntl } from 'react-intl'
import accountsStore from '../../stores/accounts-store'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import { makeStyles } from '@material-ui/styles'
import Grid from '@material-ui/core/Grid'
import Toast from '../../common/toast/toast'
import { ReactComponent as LinkChain } from '../../resources/icons/LinkChain.svg'
import { copyToClipboard } from '../../common/clipboard/clipboard'

const useStyles = makeStyles(theme => ({
  title: {
    textAlign: 'left',
    paddingBottom: 20,
    [theme.breakpoints.down(600)]: {
      fontSize: 30,
    },
    fontSize: 50,
    fontFamily: theme.typography.titleFont,
  },
}))

const AccountDetails = () => {
  const classes = useStyles()
  const accounts = useContext(accountsStore)
  const [toastMessage, setToastMessage] = React.useState()
  const [fieldValueVisibility, setFieldValueVisibility] = useState({})
  const intl = useIntl()
  const handleFieldValueVisibility = field => () => {
    setFieldValueVisibility(fieldValueVisibility => ({
      ...fieldValueVisibility,
      [field]: !fieldValueVisibility[field],
    }))
  }

  const copy = (value, message) => () => {
    copyToClipboard(value)
    setToastMessage(message)
  }

  return (
    <>
      <Typography
        variant="h3"
        paragraph
        align="center"
        className={classes.title}
      >
        {intl.formatMessage({ id: 'account.title' })}
      </Typography>
      <Grid container direction="column" spacing={24}>
        <Grid item>
          <TextField
            variant="outlined"
            label={intl.formatMessage({ id: 'account.id' })}
            value={accounts.current.id}
            margin="normal"
            fullWidth
            disabled
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton
                    aria-label={intl.formatMessage({ id: 'account.id.copy' })}
                    onClick={copy(
                      accounts.current.id,
                      intl.formatMessage(
                        { id: 'general.clipboard' },
                        {
                          field: intl.formatMessage({
                            id: 'account.id',
                          }),
                        },
                      ),
                    )}
                  >
                    <LinkChain />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item>
          <TextField
            variant="outlined"
            label={intl.formatMessage({ id: 'account.publicKey' })}
            value={accounts.current.publicKey.toHex()}
            margin="normal"
            fullWidth
            disabled
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton
                    aria-label={intl.formatMessage({
                      id: 'account.publicKey.copy',
                    })}
                    onClick={copy(
                      accounts.current.publicKey.toHex(),
                      intl.formatMessage(
                        { id: 'general.clipboard' },
                        {
                          field: intl.formatMessage({
                            id: 'account.publicKey',
                          }),
                        },
                      ),
                    )}
                  >
                    <LinkChain />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        {accounts.current.mnemonic && (
          <Grid item>
            <TextField
              fullWidth
              label={intl.formatMessage({ id: 'account.backupPhrase' })}
              variant="outlined"
              value={accounts.current.mnemonic}
              disabled
              type={fieldValueVisibility.phrase ? 'text' : 'password'}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton
                      aria-label={intl.formatMessage({
                        id: 'account.backupPhrase.copy',
                      })}
                      onClick={copy(
                        accounts.current.mnemonic,
                        intl.formatMessage(
                          { id: 'general.clipboard' },
                          {
                            field: intl.formatMessage({
                              id: 'account.backupPhrase',
                            }),
                          },
                        ),
                      )}
                    >
                      <LinkChain />
                    </IconButton>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label={intl.formatMessage({
                        id: 'account.backupPhrase.visibility',
                      })}
                      onClick={handleFieldValueVisibility('phrase')}
                    >
                      {fieldValueVisibility.phrase ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        )}
        <Grid item>
          <TextField
            label={intl.formatMessage({ id: 'account.privateKey' })}
            variant="outlined"
            value={accounts.current.privateKey.toHex()}
            fullWidth
            disabled
            type={fieldValueVisibility.privateKey ? 'text' : 'password'}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton
                    aria-label={intl.formatMessage({
                      id: 'account.privateKey.copy',
                    })}
                    onClick={copy(
                      accounts.current.privateKey.toHex(),
                      intl.formatMessage(
                        { id: 'general.clipboard' },
                        {
                          field: intl.formatMessage({
                            id: 'account.privateKey',
                          }),
                        },
                      ),
                    )}
                  >
                    <LinkChain />
                  </IconButton>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label={intl.formatMessage({
                      id: 'account.privateKey.visibility',
                    })}
                    onClick={handleFieldValueVisibility('privateKey')}
                  >
                    {fieldValueVisibility.privateKey ? (
                      <Visibility />
                    ) : (
                      <VisibilityOff />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
      {toastMessage && (
        <Toast message={toastMessage} close={() => setToastMessage(null)} />
      )}
    </>
  )
}

export default AccountDetails
