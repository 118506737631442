import React from 'react'
import { useIntl } from 'react-intl'
import { NavLink } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import MuiLink from '@material-ui/core/Link'
import makeStyles from '@material-ui/styles/makeStyles'

const useStyles = makeStyles(theme => ({
  link: {
    color: 'white',
    [theme.breakpoints.down(700)]: {
      fontSize: '0.9rem',
    },
  },
}))

const MenuInline = ({ routes }) => {
  const classes = useStyles()
  const intl = useIntl()

  return (
    <>
      {routes.map(
        ({ title, routeProps }) =>
          title && (
            <Grid item key={routeProps.path}>
              <MuiLink
                className={classes.link}
                underline="none"
                color="inherit"
                activeClassName="active"
                exact
                to={routeProps.path}
                component={NavLink}
              >
                {intl.formatMessage({ id: title })}
              </MuiLink>
            </Grid>
          ),
      )}
    </>
  )
}

export default MenuInline
