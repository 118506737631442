import Config from '../config'

console.log('Config = ', Config)

export const verifyEmail = (email, operation) => {
  return fetch(`${Config.IDP_ADDRESS}/account/verify-email`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email,
      operation,
    }),
  })
}

export const registerCustodialWallet = data => {
  return fetch(`${Config.CUSTODIAL_WALLET_ADDRESS}/account/register`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
}

export const resetPassword = data => {
  return fetch(`${Config.CUSTODIAL_WALLET_ADDRESS}/account/reset-password`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
}

export const loginCustodialWallet = data => {
  data.idpName = data.idpName || 'Chromia'

  return fetch(`${Config.CUSTODIAL_WALLET_ADDRESS}/account/authenticate`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
}
