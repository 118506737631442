import { Blockchain } from 'ft3-lib'
import React, { useState, useEffect, useContext } from 'react'
import makeStyles from '@material-ui/styles/makeStyles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { useIntl } from 'react-intl'
import { registerCustodialWallet, verifyEmail } from '../../api/api'
import Config from '../../config'
import DirectoryService from '../../lib/directory-service'
import { fromRawTransaction, generateUserData } from '../../lib/utils'
import {
  validate,
  required,
  equalValues,
  minLength,
} from '../../lib/validations'
import WalletButton from '../../material/button'
import Grid from '@material-ui/core/Grid'
import * as eccrypto from 'eccrypto-js'
import crypto from 'crypto'
import accountsStore from '../../stores/accounts-store'

const useStyles = makeStyles(theme => ({
  wrapper: {
    maxWidth: 600,
    margin: 'auto',
    marginTop: theme.spacing.unit * 10,
    [theme.breakpoints.down(500)]: {
      padding: `0 ${theme.spacing.unit * 1.5}px`,
    },
    [`${theme.breakpoints.down(700)} and (orientation: landscape)`]: {
      marginTop: 0,
    },
  },
  title: {
    [theme.breakpoints.down(600)]: {
      fontSize: 30,
    },
    fontSize: 50,
    fontFamily: theme.typography.titleFont,
  },
  actions: {
    margin: `${theme.spacing.unit * 4}px 0`,
    textAlign: 'center',
  },
  fields: {
    marginTop: theme.spacing.unit * 6,
    [`${theme.breakpoints.down(700)} and (orientation: landscape)`]: {
      marginTop: theme.spacing.unit,
    },
  },
  or: {
    margin: `${theme.spacing.unit}px 0`,
  },
}))

const CustodialAccountForm = ({ onContinue, initialAccount }) => {
  const [account, setAccount] = useState({})
  const [error, setError] = useState('')
  const classes = useStyles()
  const [mailSent, setMailSent] = React.useState(false)
  const [busy, setBusy] = React.useState(false)
  const intl = useIntl()
  const accounts = useContext(accountsStore)

  const scrollHookRef = React.useRef(null)

  useEffect(() => {
    setError('')
  }, [account])

  useEffect(() => {
    if (!error && !mailSent) {
      return
    }
    scrollHookRef.current.scrollIntoView()
  }, [error, mailSent])

  useEffect(() => {
    setAccount(initialAccount || {})
  }, [initialAccount])

  const runVerification = async (data, keyPair) => {
    try {
      const kp = crypto
        .createHash('sha256')
        .update(keyPair.pubKey.toString('hex'))
        .digest()
      await eccrypto.verify(
        Buffer.from(data.registrationRecord.userAuthPubKey, 'hex'),
        kp,
        Buffer.from(data.clientPubKeySignature, 'hex'),
      )

      const rr = crypto
        .createHash('sha256')
        .update(JSON.stringify(data.registrationRecord))
        .digest()
      await eccrypto.verify(
        Buffer.from(Config.OUT_PUBLIC_KEY, 'hex'),
        rr,
        Buffer.from(data.registrationRecordSignature, 'hex'),
      )
      return true
    } catch (err) {
      return false
    }
  }

  const handleContinueClick = async e => {
    e.preventDefault()
    const { token, password, email, repeatPassword } = account
    if (accounts.hasAccount(email)) {
      setError('sign.up.error.email.used')
      return
    }

    if (
      !validate(
        [
          [token, [required('sign.up.error.token')]],
          [
            password,
            [
              required('sign.up.error.password'),
              minLength(6, 'sign.up.error.minPassword'),
            ],
          ],
          [repeatPassword, [equalValues(password, 'sign.up.error.verify')]],
        ],
        setError,
      )
    ) {
      return
    }

    setBusy(true)
    const { encMsg, keyPair } = generateUserData(email, password)

    const response = await registerCustodialWallet({
      name: email,
      identityProviderMsg: token,
      encMsg,
    })
    if (!response.ok) {
      setError('sign.up.unknownError')
      return setBusy(false)
    }
    const data = await response.json()
    const isVerified = await runVerification(data, keyPair)

    if (!isVerified) {
      setError('sign.up.verificationError')
      return setBusy(false)
    }

    const blockchain = await Blockchain.initialize(
      Config.chainId.toBuffer(),
      new DirectoryService(),
    )

    const rawTx1 = Buffer.from(data.registerAccountRawTx, 'hex')
    await blockchain.postRaw(rawTx1)

    const rawTx2 = Buffer.from(data.addClientAuthDescriptorRawTx, 'hex')
    const tx = fromRawTransaction(rawTx2, blockchain)
    tx.sign(keyPair).post()
    setBusy(false)

    onContinue({ keyPair, authData: data, email })
  }

  const handleTextFieldChange = ({ target: { name, value } }) => {
    setAccount(account => ({ ...account, [name]: value }))
  }

  const sendVerification = async () => {
    try {
      setBusy(true)
      const response = await verifyEmail(account.email, 'create-account')
      if (!response.ok) {
        return setError('sign.up.unknownError')
      } else {
        setMailSent(true)
        setError('')
      }
    } catch (err) {
      console.log('err = ', err)
    } finally {
      setBusy(false)
    }
  }

  return (
    <form className={classes.wrapper} onSubmit={handleContinueClick}>
      <Typography
        variant="h3"
        paragraph
        align="center"
        className={classes.title}
      >
        {intl.formatMessage({ id: 'sign.up.title' })}
      </Typography>
      <Typography variant="subtitle1" align="center">
        {intl.formatMessage({ id: 'sign.up.subtitle' })}
      </Typography>
      <span ref={scrollHookRef} />
      {error && (
        <Typography color="error" align="center">
          {intl.formatMessage({ id: error })}
        </Typography>
      )}
      <Grid
        item
        container
        direction="column"
        spacing={24}
        className={classes.fields}
      >
        <Grid item xs>
          <TextField
            variant="outlined"
            label="Email"
            name="email"
            fullWidth
            value={account.email || ''}
            onChange={handleTextFieldChange}
            autoFocus
          />
        </Grid>
        {mailSent && (
          <Grid item xs>
            <TextField
              variant="outlined"
              label="Confirmation Code"
              name="token"
              fullWidth
              value={account.token || ''}
              onChange={handleTextFieldChange}
            />
          </Grid>
        )}
        {account.token && (
          <>
            <Grid item xs>
              <TextField
                variant="outlined"
                label={intl.formatMessage({ id: 'general.password' })}
                type="password"
                name="password"
                fullWidth
                value={account.password || ''}
                onChange={handleTextFieldChange}
              />
            </Grid>
            <Grid item xs>
              <TextField
                variant="outlined"
                label={intl.formatMessage({ id: 'sign.up.verify' })}
                type="password"
                fullWidth
                name="repeatPassword"
                value={account.repeatPassword || ''}
                onChange={handleTextFieldChange}
              />
            </Grid>
          </>
        )}
      </Grid>
      <div className={classes.actions}>
        {account.token ? (
          <WalletButton type="submit" busy={busy}>
            {intl.formatMessage({ id: 'sign.up.custodial.submit' })}
          </WalletButton>
        ) : (
          <WalletButton
            type="button"
            busy={busy}
            disabled={!account.email || busy || Boolean(error)}
            onClick={sendVerification}
          >
            {intl.formatMessage({ id: 'sign.up.sendConfirmationCode' })}
          </WalletButton>
        )}
      </div>
    </form>
  )
}

export default CustodialAccountForm
