import { action, decorate, observable } from 'mobx'
import { createContext } from 'react'

const STORE_KEY = 'CHROMA_INTL_LANG'

class IntlStore {
  constructor() {
    this.lang = 'en' // window.localStorage.getItem(STORE_KEY) || 'en'
  }

  options = [
    {
      lang: 'en',
      label: 'English',
    },
    // {
    //   lang: 'fr',
    //   label: 'French',
    // },
  ]

  changeLanguage = lang => {
    window.localStorage.setItem(STORE_KEY, lang)
    this.lang = lang
  }

  getFlag(lang) {
    const iso = lang === 'en' ? 'gb' : lang
    return iso
      .toUpperCase()
      .replace(/./g, char => String.fromCodePoint(char.charCodeAt(0) + 127397))
  }

  get langName() {
    return this.options.find(o => o.lang === this.lang).label
  }
}

decorate(IntlStore, {
  lang: observable,
  changeLanguage: action,
})

export const intlStore = new IntlStore()
window.intlStore = intlStore

export default createContext(intlStore)
